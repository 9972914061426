import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "antd";
import pptxgen from "pptxgenjs";
import ChartsPDF from "./ChartsPdf";
import html2canvas from "html2canvas";

const createSlideElement = (content) => {
  const slideDiv = document.createElement("div");
  slideDiv.style.width = "1920px";
  slideDiv.style.height = "1080px";
  slideDiv.style.position = "absolute";
  slideDiv.style.left = "-9999px";
  slideDiv.style.top = "-9999px";
  slideDiv.style.backgroundColor = "#FFFFFF";
  slideDiv.style.padding = "60px"; // Increased padding
  slideDiv.innerHTML = content;
  return slideDiv;
};

export const usePowerPointExport = () => {
  const [isExporting, setIsExporting] = useState(false);

  const exportToPowerPoint = async (data) => {
    const {
      metrics,
      pieChartImg,
      scatterChartImg,
      objChartImg,
      gantImage,
      quarterImg,
      contributeImg,
    } = data;
    setIsExporting(true);
    const pptx = new pptxgen();

    // Set default slide properties
    pptx.layout = 'LAYOUT_16x9';
    pptx.defineLayout({
      name: 'CUSTOM',
      width: 10,
      height: 5.625
    });

    try {
      // Create slides dynamically and track content height
      const createAndCaptureSlide = async (title, contentHtml) => {
        const container = document.createElement("div");
        container.style.position = "absolute";
        container.style.left = "-9999px";
        container.style.top = "-9999px";
        document.body.appendChild(container);

        const slideElement = createSlideElement(`
          <div style="
            font-family: Poppins;
            height: 100%;
            display: flex;
            flex-direction: column;
            background: white;
          ">
            <h1 style="
              font-size: 48px;
              font-weight: bold;
              margin-bottom: 40px;
              color: #1a1a1a;
              text-align: left;
              padding-bottom: 20px;
              border-bottom: 3px solid #e0e0e0;
            ">${title}</h1>
            <div style="flex: 1; display: flex; flex-direction: column; justify-content: center;">
              ${contentHtml}
            </div>
          </div>
        `);
        container.appendChild(slideElement);

        await new Promise((resolve) => setTimeout(resolve, 500));

        const slideCanvas = await html2canvas(slideElement, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: "#FFFFFF",
          width: 1920,
          height: 1080,
        });

        container.remove();
        return slideCanvas.toDataURL("image/png");
      };

      // Metrics Slide
      if (metrics) {
        const metricsContent = metrics
          .map(
            (metric) => `
            <div style="
              background: white;
              padding: 24px;
              border-radius: 12px;
              border: 2px solid #e0e0e0;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
              transition: transform 0.2s;
            ">
              <h3 style="
                font-size: 24px;
                font-weight: 500;
                color: #666;
                margin-bottom: 12px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
              ">${metric.label}</h3>
              <p style="
                font-size: 36px;
                font-weight: bold;
                color: #1a1a1a;
                margin: 0;
              ">${metric.value}</p>
            </div>
          `
          )
          .join("");

        const metricsImage = await createAndCaptureSlide(
          "Executive Dashboard",
          `<div style="
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 32px;
            padding: 20px;
          ">${metricsContent}</div>`
        );

        const slide = pptx.addSlide();
        slide.addImage({ data: metricsImage, x: 0, y: 0, w: "100%", h: "100%" });
      }

      // Goals Overview Slide
      if (pieChartImg || scatterChartImg) {
        const chartsContent = `
          <div style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 40px;
            padding: 20px;
            height: 100%;
          ">
            ${pieChartImg ? 
              `<div style="
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                padding: 20px;
                border-radius: 12px;
                border: 2px solid #e0e0e0;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
              ">
                <img src="${pieChartImg}" style="max-height: 100%; width: auto; object-fit: contain;" />
              </div>` 
              : ""
            }
            ${scatterChartImg ? 
              `<div style="
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                padding: 20px;
                border-radius: 12px;
                border: 2px solid #e0e0e0;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
              ">
                <img src="${scatterChartImg}" style="max-height: 100%; width: auto; object-fit: contain;" />
              </div>`
              : ""
            }
          </div>
        `;

        const chartsImage = await createAndCaptureSlide("Goals Overview", chartsContent);
        const slide = pptx.addSlide();
        slide.addImage({ data: chartsImage, x: 0, y: 0, w: "100%", h: "100%" });
      }

      // Function to create image slides with consistent styling
      const createImageSlide = async (title, imageUrl) => {
        const content = `
          <div style="
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
          ">
            <div style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: white;
              padding: 20px;
              border-radius: 12px;
              border: 2px solid #e0e0e0;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
            ">
              <img src="${imageUrl}" style="max-height: 100%; width: auto; object-fit: contain;" />
            </div>
          </div>
        `;

        const slideImage = await createAndCaptureSlide(title, content);
        const slide = pptx.addSlide();
        slide.addImage({ data: slideImage, x: 0, y: 0, w: "100%", h: "100%" });
      };

      // Create remaining slides
      if (objChartImg) {
        await createImageSlide("Overall Objective Summary", objChartImg);
      }

      if (gantImage) {
        await createImageSlide("Key Result Breakdown", gantImage);
      }

      if (quarterImg) {
        await createImageSlide("Quarterly Overview", quarterImg);
      }

      if (contributeImg) {
        await createImageSlide("Key Contributions", contributeImg);
      }

      await pptx.writeFile("Comprehensive_Report.pptx");
    } catch (error) {
      console.error("Failed to export PowerPoint:", error);
      throw error;
    } finally {
      setIsExporting(false);
    }
  };

  return { exportToPowerPoint, isExporting };
};

const ModalContent = ({
  metrics,
  objChartImg,
  pieChartImg,
  scatterChartImg,
  gantImage,
  quarterImg,
  contributeImg,
  handleModalClose,
}) => {
  const [exportingPPT, setExportingPPT] = useState(false);
  const { exportToPowerPoint } = usePowerPointExport();

  const handleExport = async () => {
    setExportingPPT(true);
    try {
      await exportToPowerPoint({
        metrics,
        pieChartImg,
        scatterChartImg,
        objChartImg,
        gantImage,
        quarterImg,
        contributeImg,
      });
      handleModalClose();
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setExportingPPT(false);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <PDFDownloadLink
        document={
          <ChartsPDF
            metrics={metrics}
            pieChartImg={pieChartImg}
            scatterChartImg={scatterChartImg}
            objChartImg={objChartImg}
            gantImage={gantImage}
            quarterImg={quarterImg}
            contributeImg={contributeImg}
          />
        }
        fileName="Comprehensive_Reports.pdf"
        onSuccess={handleModalClose}
        onError={handleModalClose}
      >
        {({ loading }) => (
          <Button
            className="w-full inline-flex items-center gap-2 px-4 py-2 rounded-md"
            disabled={loading}
          >
            Export as PDF
          </Button>
        )}
      </PDFDownloadLink>

      <Button
        onClick={handleExport}
        disabled={exportingPPT}
        loading={exportingPPT}
        className="w-full inline-flex items-center gap-2 px-4 py-2 rounded-md"
      >
        {exportingPPT ? "Exporting..." : "Export as PPT"}
      </Button>
    </div>
  );
};

export default ModalContent;