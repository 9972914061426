import React from "react";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Scatter,
} from "recharts";

const GoalCharts = ({
  COLORS,
  statsData,
  pieChartRef,
  scatterChartRef,
  chartsWrapperRef,
}) => {
  const Sessions = useSelector((state) => state.user?.sessionList);
  const circleData = [
    {
      name: "Completed",
      value: statsData?.goal_progress?.completed_percentage,
    },
    {
      name: "In Progress",
      value: statsData?.goal_progress?.in_progress_percentage,
    },
    {
      name: "Not Started",
      value: statsData?.goal_progress?.not_started_percentage,
    },
  ];

  const bubbleData = Object.entries(statsData?.goal_quarter_progress || {}).map(
    ([quarter, progress], index) => {
      const matchingQuarter = Sessions?.find((q) => q.value === quarter);
      return {
        quarter: matchingQuarter?.label || quarter,
        goalsCompleted: progress?.completed_percentage || 0,
        x: index + 1,
        y: progress?.completed_percentage || 0,
        z: 30,
      };
    }
  );

  return (
    <div>
      <div
        className="charts-wrapper flex space-x-8 mb-4"
        ref={chartsWrapperRef}
      >
        <div
          className="chart-container bg-white flex-1 p-3  rounded"
          ref={pieChartRef}
        >
          <div className="w-full">
            <h3 className="text-lg font-semibold text-center mb-6">
              Overall Goals
            </h3>

            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <defs>
                    {COLORS.map((color, index) => (
                      <linearGradient
                        key={`gradient-${index}`}
                        id={`pieGradient-${index}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop offset="0%" stopColor={color.start} />
                        <stop offset="100%" stopColor={color.end} />
                      </linearGradient>
                    ))}
                  </defs>

                  <Pie
                    data={circleData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    startAngle={90}
                    endAngle={450}
                    labelLine={false}
                  >
                    {circleData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={`url(#pieGradient-${index})`}
                        stroke="none"
                      />
                    ))}
                  </Pie>

                  <Tooltip
                    contentStyle={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "8px",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="flex  gap-x-6 text-xs mt-6 w-full">
              {circleData.map((entry, index) => (
                <div
                  key={`legend-${index}`}
                  className="inline-flex items-center mr-6 mb-2 text-base"
                >
                  <div className="flex ">
                    <div
                      className="w-4 h-4 rounded-full mt-1"
                      style={{
                        background: COLORS[index]
                          ? `linear-gradient(45deg, ${COLORS[index].start}, ${COLORS[index].end})`
                          : "gray",
                      }}
                    />
                    <span className="ml-2 text-sm">
                      {entry.name} <br /> ({entry.value}%)
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="chart-container bg-white flex-1 p-3  rounded"
          ref={scatterChartRef}
        >
          <h3 className="text-center font-semibold my-4 ">
            Quarterly Goal Completion
          </h3>
          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="x"
                name="Quarter"
                domain={["auto", "auto"]}
                tickFormatter={(tick) => bubbleData[tick - 1]?.quarter}
                ticks={bubbleData.map((_, index) => index + 1)}
                tick={{ fontSize: 12, fill: "#666" }}
                label={{
                  value: "Quarters",
                  position: "insideBottom",
                  offset: -2,
                  style: { fontSize: 14, fill: "#444" },
                }}
              />
              <YAxis
                type="number"
                dataKey="y"
                name="Completion Percentage"
                domain={[0, 100]}
                tick={{ fontSize: 12, fill: "#666" }}
                label={{
                  value: "Completion %",
                  angle: -90,
                  position: "insideLeft",
                  style: { fontSize: 14, fill: "#444" },
                }}
              />
              <Tooltip
                cursor={{ strokeDasharray: "3 3", stroke: "#ccc" }}
                contentStyle={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                }}
                itemStyle={{ color: "#333" }}
                formatter={(value, name, props) => [
                  value,
                  name,
                  `Quarter: ${props?.payload?.quarter}`,
                ]}
              />
              <Scatter
                name="Goal Completion"
                data={bubbleData}
                fill="#1F76B4"
                shape="circle"
                size={bubbleData.map((d) => d.z * 10)}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default GoalCharts;
