import React, { useState } from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";
import { IoPersonOutline } from "react-icons/io5";
import { GoGraph, GoGoal } from "react-icons/go";
import { PiCircleDashed } from "react-icons/pi";
import { BiGlobe } from "react-icons/bi";
import { Progress } from "antd";
import { getAvatarColor, getInitials } from "../../../utils/helpers";
import {
  Box,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import { BsTriangleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
} from "../../../stores/slices/okrSlice";
import { CheckIcon } from "@chakra-ui/icons";

const GoalsTable = ({ goals, selectedGoalIndex, handleCheckboxChange }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [expandedGoal, setExpandedGoal] = useState(null);
  const [expandedObjective, setExpandedObjective] = useState({});

  const handleGoalToggle = (goalId) => {
    setExpandedGoal((prev) => (prev === goalId ? null : goalId));
  };

  const handleOpenDrawerWithKey = (node) => {
    if (node?.key_id) {
      if (user?.roleAccess?.["Key-Results"]?.View) {
        dispatch(handleOpenKeyresultDrawer(node?.key_id));
      }
    } else if (node?.okr_id) {
      if (user?.roleAccess?.Objectives?.View) {
        dispatch(handleOpenOkrDrawer(node?.okr_id));
      }
    } else if (node?.goal_id) {
      if (user?.roleAccess?.Goals?.View) {
        dispatch(handleOpenGoalDrawer(node?.goal_id));
      }
    } else {
    }
  };

  const handleObjectiveToggle = (objectiveId) => {
    setExpandedObjective((prev) => ({
      ...prev,
      [objectiveId]: !prev[objectiveId],
    }));
  };
  const renderOwners = (owners, type) => (
    <Box
      className="h-3/4"
      overflowY="auto"
      sx={{
        scrollBehavior: "smooth",
        "&::-webkit-scrollbar": {
          width: "2px",
          display: "block",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray.500", // Darker scrollbar thumb for contrast
          borderRadius: "10px", // More rounded scrollbar
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // Make scrollbar track invisible
        },
        MsOverflowStyle: "none", // IE and Edge
        scrollbarWidth: "thin", // Firefox
      }}
    >
      {owners
        ?.filter((owner) => owner?.type === type)
        ?.map((owner, index) => (
          <Box
            key={`owner-${type}-${index}`}
            display="flex"
            alignItems="center"
            gap={2}
            flexWrap="wrap"
            mb={2}
          >
            {type === "team" ? (
              <Box p={1} h={5} w={5} bg="gray.600" rounded="full">
                <BiGlobe className="text-gray-300 text-xs justify-center items-center" />
              </Box>
            ) : (
              <Box
                w={5}
                h={5}
                border="1px"
                borderColor="black"
                color="white"
                rounded="full"
                bg={
                  owner?.profile_image ? "initial" : getAvatarColor(owner?.name)
                }
                display="flex"
                fontSize="smaller"
                alignItems="center"
                justifyContent="center"
              >
                {owner?.profile_image ? (
                  <Image
                    src={owner?.profile_image}
                    alt="Profile"
                    borderRadius="full"
                    boxSize="100%"
                    objectFit="cover"
                  />
                ) : (
                  getInitials(owner?.name)
                )}
              </Box>
            )}
            <Text fontSize="xs" fontWeight="medium">
              {owner?.name}
            </Text>
          </Box>
        ))}
    </Box>
  );

  const renderProgress = (gain) => (
    <Box>
      <div className="flex flex-col w-full items-center justify-center gap-x-2 col-span-1">
        <div className="flex justify-between w-full">
          <div className="text-xs" style={{ color: "#0C0C0C" }}>
            {gain === 0 ? "Pending" : gain < 100 ? "In Progress" : "Completed"}
          </div>
          <div>
            <h3 className="text-xs font-bold" style={{ color: "#063F5D" }}>
              {gain}
              <span className="font-medium">%</span>
            </h3>
          </div>
        </div>
        <div className="flex justify-center w-full bg-inherit items-center">
          <Progress
            percent={gain}
            showInfo={false}
            strokeColor={gain < 100 ? "#F77C33" : "#0AA959"}
          />
        </div>
      </div>
    </Box>
  );

  const renderDescription = (description) => (
    <Text fontSize="sm" opacity={0.7}>
      {description}
    </Text>
  );

  const renderChildren = (children, paddingLeft = 0) => (
    <>
      {children?.map((item, index) => (
        <React.Fragment key={`child-${index}`}>
          <Tr border="1px solid" borderColor="gray.300">
            <Td pl={paddingLeft} border="1px solid" borderColor="gray.300">
              <Box display="flex" alignItems="center">
                <button
                  className="mr-2"
                  onClick={() =>
                    item?.children
                      ? handleObjectiveToggle(item?.okr_id)
                      : null
                  }
                  aria-label="Toggle"
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingRight: 0,
                  }}
                >
                  {!item?.children?.length ? (
                    <FaCircle color="#7C8091" size={8} />
                  ) : expandedObjective[item?.okr_id] ? (
                    <BsTriangleFill
                      color="#7C8091"
                      size={12}
                      style={{ transform: "rotate(180deg)" }}
                    />
                  ) : (
                    <BsTriangleFill
                      color="#7C8091"
                      size={12}
                      style={{ transform: "rotate(90deg)" }}
                    />
                  )}
                </button>
                <button
                  onClick={() => handleOpenDrawerWithKey(item)}
                  className="mr-2 cursor-pointer"
                >
                  <img
                    src={
                      item?.key_id
                        ? "/assets/images/keyresults.svg"
                        : "/assets/images/objective.svg"
                    }
                    style={{ width: "20px", height: "20px" }}
                    alt={item?.key_id ? "key result" : "objective"}
                  />
                </button>
                <Text
                  onClick={() => handleOpenDrawerWithKey(item)}
                  className="cursor-pointer"
                  fontSize="sm"
                  fontWeight="medium"
                  maxW="150px" // Set a fixed width
                  whiteSpace="normal" // Allow wrapping
                  wordBreak="break-word"
                >
                  {item?.title || item?.name}
                </Text>
              </Box>
            </Td>
            <Td border="1px solid" borderColor="gray.300">
              {renderOwners(item?.owners, "team")}
            </Td>
            <Td border="1px solid" borderColor="gray.300">
              {renderOwners(item?.owners, "user")}
            </Td>
            <Td border="1px solid" borderColor="gray.300">
              {renderProgress(item?.overall_gain)}
            </Td>
            <Td
              border="1px solid"
              borderColor="gray.300"
              whiteSpace="normal" // Allow wrapping
              wordBreak="break-word"
              maxW="300px" // Set a fixed width for the description
            >
              {renderDescription(item?.description)}
            </Td>
          </Tr>
          {expandedObjective[item?.okr_id] &&
            renderChildren(item?.children, paddingLeft + 8)}
        </React.Fragment>
      ))}
    </>
  );

  return (
    <Box className="overflow-x-auto h-full">
      <Table className="table-auto w-full border-collapse border  overflow-x-auto  border-gray-300 rounded-lg">
        <Thead>
          <Tr className="font-normal border h-12 rounded-lg">
            <Th
              className="px-2 py-2 text-left border-r"
              border="1px solid"
              borderColor="gray.300"
            >
              <div className="flex gap-x-2">
                <MdOutlineFilterList /> Title
              </div>
            </Th>
            <Th
              className="px-2 py-2 text-center border-r"
              border="1px solid"
              borderColor="gray.300"
            >
              <div className="flex gap-x-2">
                <AiOutlineTeam /> Team
              </div>
            </Th>
            <Th
              className="px-2 py-2 text-center border-r"
              border="1px solid"
              borderColor="gray.300"
            >
              <div className="flex gap-x-2">
                <IoPersonOutline /> Owners
              </div>
            </Th>
            <Th
              className="px-2 py-2 text-center border-r"
              border="1px solid"
              borderColor="gray.300"
            >
              <div className="flex gap-x-2">
                <GoGraph /> Progress
              </div>
            </Th>
            <Th
              className="px-2 py-2 text-center"
              border="1px solid"
              borderColor="gray.300"
            >
              <div className="flex gap-x-2">
                <PiCircleDashed /> Description
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {goals?.slice()?.map((goal, i) => (
            <React.Fragment key={`goal-${i}`}>
              <Tr>
                <Td
                  className=" h-full"
                  px={2}
                  py={2}
                  border="1px solid"
                  borderColor="gray.300"
                >
                  <Box className="flex">
                    <button
                      className="mr-2"
                      onClick={() =>
                        goal?.goal_id ? handleGoalToggle(goal?.goal_id) : null
                      }
                      aria-label="Toggle"
                      style={{
                        background: "transparent",
                        border: "none",
                        paddingRight: 0,
                      }}
                    >
                      {goal?.children?.length === 0 ? (
                        <FaCircle color="#7C8091" size={8} />
                      ) : expandedGoal === goal?.goal_id ? (
                        <BsTriangleFill
                          color="#7C8091"
                          size={12}
                          style={{ transform: "rotate(180deg)" }}
                        />
                      ) : (
                        <BsTriangleFill
                          color="#7C8091"
                          size={12}
                          style={{ transform: "rotate(90deg)" }}
                        />
                      )}
                    </button>

                    <Box
                      onClick={() => handleOpenDrawerWithKey(goal)}
                      className="flex h-full justify-center cursor-pointer items-center mr-2"
                    >
                      <button
                        className="rounded mr-2 p-1"
                        style={{
                          background:
                            "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                        }}
                      >
                        <GoGoal
                          className="text-md "
                          style={{
                            color: "#B91C1C",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </button>
                      <Text
                        fontSize="sm"
                        display="flex"
                        flexDirection={"row"}
                        alignItems="center"
                        fontWeight="medium"
                        height="100%"
                      >
                        {goal?.title || goal?.goal_name}
                      </Text>
                    </Box>
                    <Box
                      display="inline-flex" // Use inline-flex to prevent the container from stretching
                      justifyContent="center" // Center horizontally
                      alignItems="center" // Center vertically
                      style={{
                        padding: 0, // Remove extra padding
                        margin: 0, // Remove any external margin
                        width: "fit-content", // Ensure the box only takes the space of its content
                      }}
                    >
                      <div
                        className="mr-0 w-full"
                        style={{
                          padding: 0, // Remove extra padding
                          margin: 0, // Remove any external margin
                          width: "auto", // Allow width to adjust to content
                          display: "flex",
                          alignItems: "center",
                          cursor:
                            selectedGoalIndex !== i && selectedGoalIndex >= 0
                              ? "not-allowed"
                              : "pointer", // Disable click if the condition is met
                        }}
                        onClick={() => {
                          if (selectedGoalIndex !== i && selectedGoalIndex >= 0)
                            return; // Prevent action if disabled
                          handleCheckboxChange(i, goal); // Handle change
                        }}
                      >
                        <div
                          style={{
                            width: "20px", // Checkbox size
                            height: "20px", // Checkbox size
                            borderRadius: "4px", // Rounded corners
                            border:
                              selectedGoalIndex === i
                                ? "2px solid #007bff"
                                : "2px solid #ccc", // Blue border when selected
                            display: "flex", // Centering the inner check mark
                            justifyContent: "center", // Align horizontally
                            alignItems: "center", // Align vertically
                            backgroundColor:
                              selectedGoalIndex === i
                                ? "#007bff"
                                : "transparent", // Blue background when selected
                          }}
                        >
                          {/* Show checkmark when selected */}
                          {selectedGoalIndex === i && (
                            <CheckIcon
                              style={{
                                fontSize: "16px", // Adjust the size of the checkmark
                                color: "#fff", // Tick mark color
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Td>
                <Td border="1px solid" borderColor="gray.300">
                  {renderOwners(goal?.owners, "team")}
                </Td>
                <Td border="1px solid" borderColor="gray.300">
                  {renderOwners(goal?.owners, "user")}
                </Td>
                <Td border="1px solid" borderColor="gray.300">
                  {renderProgress(goal?.overall_gain)}
                </Td>
                <Td
                  border="1px solid"
                  borderColor="gray.300"
                  whiteSpace="pre-wrap"
                  wordBreak="break-word"
                  maxW="300px"
                >
                  {renderDescription(goal?.description)}
                </Td>
              </Tr>
              {expandedGoal === goal?.goal_id &&
                renderChildren(goal?.children, 8)}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default GoalsTable;
