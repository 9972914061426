import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTitleData } from "../stores/slices/userAuthSlice";
import { baseURL } from "../utils/config";
import { api } from "../components/global/api";
import { GoGoal } from "react-icons/go";
import CustomScrollbar from "../components/global/CustomScrollBar";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";

const Trash = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading,setLoading] = useState(false);

  const fetchData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await api.get(baseURL + "/delete-item/", {
        company_id: currentEmployee?.company_id,
      });
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRestore = (id, type) => {
    setSelectedItem({ id, type });
    onOpen();
  };

  const handleConfirmRestore = async () => {
    setLoading(true);
    const { id, type } = selectedItem;
    let payload = {};

    if (type === "Goal") {
      payload.goal_id = id;
    } else if (type === "OKR") {
      payload.okr_id = id;
    } else {
      payload.key_id = id;
    }
    try {
      const response = await api.post(baseURL + "/delete-item/", payload);
      toast.success(`${type} Restored Successfully `);
      setLoading(false);
      setLoading(false);
    } catch (error) {
      console.error("Error restoring item:", error);
      toast.success("Please Try Again");
      setLoading(false);
    } finally {
      fetchData();
      onClose();
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(
      updateTitleData({
        title: "Trash",
        ClickLink: null,
      })
    );
  }, []);

  const isEmpty =
    (!data?.goals || data.goals.length === 0) &&
    (!data?.okrs || data.okrs.length === 0) &&
    (!data?.key_results || data.key_results.length === 0);

  return (
    <div className="w-screen md:w-full h-full p-4 font-poppins">
      <div className="border-b-2 border-gray-300 mb-4 mt-2"></div>
      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "73vh",
        }}
      >
        <div className="overflow-y-hidden h-full">
          <CustomScrollbar>
            {isEmpty ? (
              <div className="flex items-center justify-center h-full">
                <div className="text-center">
                  <h1 className="text-gray-500 text-center mt-2">
                    No Data Available
                  </h1>
                  <p className="text-gray-400">
                    Deleted Goals, OKRs, or Key Results will appear here.
                  </p>
                </div>
              </div>
            ) : (
              <>
                {data?.goals?.length > 0 && (
                  <div className="space-y-4">
                    <h2 className="text-lg font-bold mb-4">Goals Overview</h2>
                    {data?.goals.map((goal) => (
                      <div
                        key={goal.goal_id}
                        className="bg-white p-4 rounded-md shadow-md flex justify-between items-center"
                      >
                        <div className="flex items-center mb-4">
                          <button
                            className="rounded mr-2 p-1"
                            style={{
                              background:
                                "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                            }}
                          >
                            <GoGoal
                              className="w-8 h-8 rounded-full"
                              style={{
                                color: "#B91C1C",
                              }}
                            />
                          </button>
                          <div>
                            <h3 className="text-xl font-semibold">
                              {goal.title}
                            </h3>
                            <p className="text-gray-500 text-center mt-2">
                              {goal.description || "No description provided"}
                            </p>
                          </div>
                        </div>
                        {/* Flex container to align the Restore button to the right */}
                        <div className="flex justify-between items-center">
                          <button
                            onClick={() => handleRestore(goal?.goal_id, "Goal")}
                            className="bg-blue-500 text-white p-2 rounded-md"
                          >
                            Restore
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="mt-6"></div>

                {data?.okrs.length > 0 && (
                  <div className="space-y-4">
                    <h2 className="text-lg font-bold mb-4">OKRs Overview</h2>
                    {data?.okrs.map((okr) => (
                      <div
                        key={okr.id}
                        className="bg-white p-4 rounded-md shadow-md flex justify-between items-center"
                      >
                        <div className="flex items-center mb-4">
                          <img
                            src="/assets/images/objective.svg"
                            alt="profile"
                            className="w-8 h-8 rounded-full mr-2"
                          />
                          <div>
                            <h3 className="text-xl font-semibold">
                              {okr.title}
                            </h3>
                            <p className="text-gray-500 text-center mt-2">
                              {okr.description || "No description provided"}
                            </p>
                          </div>
                        </div>
                        {/* Flex container to align the Restore button to the right */}
                        <div className="flex justify-between items-center">
                          <button
                            onClick={() => handleRestore(okr?.okr_id, "OKR")}
                            className="bg-blue-500 text-white p-2 rounded-md"
                          >
                            Restore
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Add space between sections */}
                <div className="mt-6"></div>

                {data?.key_results.length > 0 && (
                  <div className="space-y-4">
                    <h2 className="text-lg font-bold mb-4">
                      Key Results Overview
                    </h2>
                    {data?.key_results.map((keyResult) => (
                      <div
                        key={keyResult.id}
                        className="bg-white p-4 rounded-md shadow-md flex justify-between items-center"
                      >
                        <div className="flex items-center mb-4">
                          <img
                            src="/assets/images/keyresults.svg"
                            alt="profile"
                            className="w-8 h-8 rounded-full mr-2"
                          />
                          <div>
                            <h3 className="text-xl font-semibold">
                              {keyResult?.title}
                            </h3>
                            <p className="text-gray-500 text-center mt-2">
                              {keyResult.description ||
                                "No description provided"}
                            </p>
                          </div>
                        </div>
                        {/* Flex container to align the Restore button to the right */}
                        <div className="flex justify-between items-center">
                          <button
                            onClick={() =>
                              handleRestore(keyResult?.key_id, "Key Result")
                            }
                            className="bg-blue-500 text-white p-2 rounded-md"
                          >
                            Restore
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </CustomScrollbar>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Restore</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to restore this{" "}
              {selectedItem?.type ? selectedItem.type : "item"}?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" isDisabled={loading} onClick={handleConfirmRestore}>
              Yes, Restore
            </Button>
            <Button onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Trash;
