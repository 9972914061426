import { Link as ScrollLink } from "react-scroll";
import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Text,
  Image,
  Divider,
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handleNavigation = (to) => {
    const element = document.getElementById(to);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      navigate("/");
    }
  };

  return (
    <Box bg="gray.50" color="gray.700">
      <Container maxW="6xl" py={10}>
        {/* Top Section */}
        <Flex
          align="center"
          gap={4}
          justify="space-between"
          width="100%"
          direction={["column", "row"]} // Stack vertically on small screens
          flexWrap="wrap" // Handle content overflow
        >
          <Image
            src="/assets/images/imongu2.svg"
            alt="Logo"
            height="50px"
            width="100px"
            loading="lazy"
            decoding="async"
          />
          <ScrollLink
            to="top"
            spy={true}
            smooth={true}
            duration={500}
            offset={-50}
            title="Back to Top"
          >
            <Flex
              align="center"
              gap={2}
              color="gray.600"
              cursor="pointer"
              fontSize={["sm", "md"]} // Adjust font size for responsiveness
            >
              Back To Top
              <ChevronUpIcon />
            </Flex>
          </ScrollLink>
        </Flex>

        {/* Middle Section */}
        <SimpleGrid columns={[1, 1, 2]} spacing={10} mt={8}>
          <Box>
            <Flex direction="column" gap={4}>
              <Text
                color="gray.600"
                fontSize={["sm", "md"]}
                maxW="lg"
                noOfLines={3}
                width={["100%", "80%"]} // Adjust width for smaller screens
              >
                Unlock the power of modern OKR management with a platform for
                focus, real-time feedback, and advanced performance insights.
              </Text>
            </Flex>
          </Box>

          {/* Navigation Links */}
          <Flex
            justify={["center", "space-between"]}
            align="center"
            direction={["column", "row"]} // Stack vertically on smaller screens
            gap={[4, 6]}
            fontSize={["xs", "sm", "md"]} // Adjust font size based on screen size
            flexWrap="wrap" // Handle wrapping for smaller screens
          >
            <Text
              as="a"
              href="#pricing"
              onClick={handleNavigation}
              color="gray.600"
              mb={[2, 0]}
            >
              Pricing
            </Text>
            <Text
              as="a"
              href="#features"
              onClick={handleNavigation}
              color="gray.600"
              mb={[2, 0]}
            >
              Features
            </Text>
            <Text as="a" href="/resources" color="gray.600" mb={[2, 0]}>
              Resources
            </Text>
            <Text as="a" href="/contactus" color="gray.600" mb={[2, 0]}>
              Contact Us
            </Text>
            <Text as="a" href="/login" color="gray.600" mb={[2, 0]}>
              Sign In
            </Text>
          </Flex>
        </SimpleGrid>

        {/* Divider */}
        <Divider my={6} />

        {/* Bottom Section */}
        <Flex
          justify={["center", "space-between"]}
          align="center"
          direction={["column", "row"]}
          gap={4}
          flexWrap="wrap" 
        >
          <Text
            fontSize={["xs", "sm"]}
            color="gray.600"
            textAlign={["center", "left"]} 
          >
            Copyright © {currentYear} iMongu
          </Text>
          <Flex
            gap={6}
            color="gray.600"
            fontSize={["xs", "sm"]}
            justify={["center", "flex-start"]}
            flexWrap="wrap" // Handle overflow for smaller screens
          >
            <Text as="a" href="/privacypolicy">
              Privacy Policy
            </Text>
            <Text as="a" href="/termsofservices">
              Terms of Service
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
