import React from "react";
import { BiCheck } from "react-icons/bi";
import { useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { Tooltip, Button } from "antd";
import { api } from "./api";
import { toast, ToastContainer } from "react-toastify";
import { baseURL } from "../../utils/config";

const SwitchAccount = ({ handleCompanyChange }) => {
  const user = useSelector((state) => state?.user);
  const currentUser = user?.currentUser;
  const currentCompany = user?.currentCompany;

  const handleSendEmail = async (employee) => {
    try {
      const response = await api.post(baseURL + "/reactive/", {
        admin_email: employee?.email,
        user_email: currentUser?.email,
      });
      const data = await response.json();
      if (data.message) {
        toast.success(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      toast({
        title: "An error occurred. Please try again later.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <form className="p-4 flex flex-col w-full" aria-label="Company switcher">
      <h1 className="text-xl text-center font-semibold">Switch Companies</h1>

      <div className="flex flex-col w-full mt-4 gap-y-2">
        {currentUser?.employees?.map((employee, index) => (
          <Tooltip
            key={employee?.id ?? index} // Better to use unique ID if available
            title={
              employee?.deactivated
                ? "This account is deactivated. Contact the admin to reactivate it."
                : ""
            }
          >
            <div className="flex flex-col">
              <div
                role="button"
                tabIndex={employee?.deactivated ? -1 : 0}
                aria-disabled={employee?.deactivated}
                aria-selected={currentCompany === index}
                aria-label={`Switch to ${employee.username}'s company${
                  employee?.deactivated ? " (deactivated)" : ""
                }`}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !employee?.deactivated) {
                    handleCompanyChange(index);
                  }
                }}
                className={`flex items-center w-full p-4 border-b rounded-md transition-all gap-x-2 ${
                  currentCompany === index
                    ? "shadow bg-slate-50"
                    : employee?.deactivated
                    ? "bg-gray-200 cursor-not-allowed"
                    : "hover:bg-slate-200 cursor-pointer"
                }`}
                onClick={() => {
                  if (!employee?.deactivated) {
                    handleCompanyChange(index);
                  }
                }}
              >
                {currentCompany === index && (
                  <BiCheck
                    className="text-base text-green-700"
                    aria-hidden="true"
                  />
                )}

                <div className="flex items-center gap-x-2 text-sm font-semibold">
                  <h4>Owner:</h4>
                  <div className="flex items-center gap-x-2">
                    <div
                      className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                      style={{
                        backgroundColor: employee?.profile_image
                          ? "initial"
                          : getAvatarColor(employee?.username),
                      }}
                      size={18}
                    >
                      {employee?.profile_image ? (
                        <img
                          src={employee.profile_image}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                            display: "block",
                          }}
                        />
                      ) : (
                        getInitials(employee?.username)
                      )}
                    </div>
                    <span
                      className={`${
                        employee?.deactivated ? "text-gray-500" : "text-black"
                      }`}
                    >
                      {employee.username}
                    </span>
                  </div>
                </div>
              </div>

              {employee?.deactivated && (
                <div className="mt-2 text-center">
                  <Button
                    type="primary"
                    onClick={() => handleSendEmail(employee)}
                    size="small"
                    aria-label={`Contact admin about ${employee.username}'s deactivated account`}
                  >
                    Contact Admin
                  </Button>
                </div>
              )}
            </div>
          </Tooltip>
        ))}
      </div>
    </form>
  );
};

export default SwitchAccount;
