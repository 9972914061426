import React from "react";
import { useNavigate } from "react-router-dom";
import KeyresultsReportTable from "./KeyresultsReportTable";
import OkrReportsTable from "./OkrReportTable";
import WestIcon from "@mui/icons-material/West";
import GaolReportsTable from "./GoalReportTable";
import TeamReportsTable from "./TeamReportTable";
import CreateReportModal from "./CreateReportModal";
import { handleCloseCreateReport } from "../../stores/slices/okrSlice";
import { useDispatch } from "react-redux";

const ReportController = ({
  type,
  keyResultReports,
  okrReports,
  goalReports,
  teamReports,
  loading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBack = () => {
    navigate("/company/insight?tab=Reports");
  };

  const fetchData = () => {};

  const handleClose = () => {
    dispatch(handleCloseCreateReport());
  };

  if (loading)
    return (
      <div className="h-full flex justify-center items-center mx-auto">
        <img src="/assets/images/Group 3.svg" alt="Loading" />
      </div>
    );

  return (
    <div className="w-full">
      <button
        onClick={handleBack}
        className="mb-4 flex items-center italic text-base space-x-1"
      >
        <p className="text-gray-400 border-2 border-gray-200 rounded-full px-2 p-1 space-x-3 flex">
          <WestIcon className="text-gray-400" />
          <span>Reports</span>
        </p>
      </button>
      {keyResultReports && (
        <KeyresultsReportTable keyResultReports={keyResultReports} />
      )}
      {okrReports && <OkrReportsTable okrReports={okrReports} />}
      {goalReports && <GaolReportsTable goalReports={goalReports} />}
      {teamReports && <TeamReportsTable teamReports={teamReports} />}
      <CreateReportModal onCancel={handleClose} fetchData={fetchData} />
    </div>
  );
};

export default ReportController;
