// reducers/slice1.js
import { createSlice } from "@reduxjs/toolkit";

const userAuthSlice = createSlice({
  name: "userslice",
  initialState: {
    email: null,
    currentUser: null,
    authLoader: false,
    authenticated: false,
    roleAccess: null,
    failed: false,
    stepcheck: 0,
    currentCompany: 0,
    refreshed: false,
    AnsList: [],
    emailverified: false,
    PlanModal: false,
    title: "Dashboard",
    linkLocation: null,
    ClickLink: null,
    currentPlan: {},
    sessionList: [],
  },

  reducers: {
    updateUserSignup: (state, action) => {
      state.currentUser = action.payload;
      state.currentCompany = 0;
      state.authenticated = true;
      localStorage.setItem("startTour", true);
      localStorage.setItem("startTouchBaseTour", true);
      
    },
    updateUserLogin: (state, action) => {
      state.currentUser = action.payload;
      state.currentCompany = 0;
      state.authenticated = true;
    },
    updateRoleAccess: (state, action) => {
      state.roleAccess = action.payload;
    },
    updateQuarter: (state, action) => {
      state.sessionList = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },

    updateEmailVerification: (state, action) => {
      state.emailverified = true;
      state.email = null;
    },
    updateTitleData: (state, action) => {
      state.title = action.payload.title;
      state.ClickLink = action.payload.ClickLink;
    },
    logout: (state) => {
      state.authenticated = false;
      state.currentUser = null;
      state.email = null;
      state.emailverified = false;
    },
    updateStepCheck: (state, action) => {
      state.stepcheck = action.payload;
    },
    updateAnsList: (state, action) => {
      state.AnsList = action.payload;
    },
    handleUpdateCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
    handleUpdatePlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    handleOpenPlanModal: (state, action) => {
      state.PlanModal = true;
    },
    handleClosePlanModal: (state, action) => {
      state.PlanModal = false;
    },
  },
});

// Export slice1 reducer and actions
export const { updateAnsList } = userAuthSlice.actions;
export const { updateStepCheck } = userAuthSlice.actions;
export const {
  updateUserSignup,
  updateTitleData,
  updateUserLogin,
  updateEmail,
  updateEmailVerification,
  updateRoleAccess,
  updateQuarter,
} = userAuthSlice.actions;
export const { logout } = userAuthSlice.actions;
export const {
  handleUpdateCurrentCompany,
  handleUpdatePlan,
  handleOpenPlanModal,
  handleClosePlanModal,
} = userAuthSlice.actions;
export default userAuthSlice.reducer;
