import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Login.css";
import { baseURL } from "../utils/config";
import { GoogleLogin } from "@react-oauth/google";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  logout,
  updateEmailVerification,
  updateUserLogin,
} from "../stores/slices/userAuthSlice";
import Cookies from "js-cookie";
import { api } from "../components/global/api";

export default function Login() {
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [reactivate, setReactivate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleAuthLogin = async (credential, provider) => {
    let signinData = {
      credential: credential,
      provider: provider,
    };

    setLoading(true);
    try {
      const result = await fetch(baseURL + "/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signinData),
      });

      if (result.status === 200) {
        const data = await result.json();
        dispatch(updateUserLogin(data));
        dispatch(updateEmailVerification(true));
        Cookies.set("access_token", data?.access_token);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "log_in",
          message: "Login Successful",
          user_name: result?.data?.username,
          user_email: result?.data?.email,
          event_ts: new Date(Date.now()).toLocaleString(),
        });
        setformData({
          email: "",
          password: "",
        });
        navigate("/home");
      } else {
        const data = await result.json();
        setformData({
          email: "",
          password: "",
        });
        toast.warn(data?.error || "Incorrect Email Or Password");
      }
    } catch (error) {
      toast.error("Something Went Wrong");
      setformData({
        email: "",
        password: "",
      });
    } finally {
      setLoading(false);
    }
  };

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const handleReactivate = async () => {
    try {
      const response = await api.post(baseURL + "/reactive/", {
        admin_email: reactivate?.admin_email,
        user_email: reactivate?.user_email,
      });
      const data = await response.json();
      if (data.message) {
        toast.warn(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      toast({
        title: "An Error Occurred. Please Try Again Later.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const saveData = async (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/;

    if (!emailRegex.test(formData.email)) {
      toast.warn("Please Enter A Valid Email Address", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (formData?.password === formData?.email) {
      toast.warn("Email And Password Should Not Be Same .", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setLoading(true);
    try {
      const signinData = {
        email: formData.email,
        password: formData.password,
      };
      const result = await fetch(baseURL + "/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signinData),
      });
      setLoading(true);
      if (result.ok) {
        const data = await result.json(); // Parse the response body as JSON
        dispatch(updateUserLogin(data));
        dispatch(updateEmailVerification(true));
        Cookies.set("access_token", data?.access_token);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "log_in",
          message: "Login Successful",
          user_name: data?.username,
          user_email: data?.email,
          event_ts: new Date(Date.now()).toLocaleString(),
        });
        setformData({
          email: "",
          password: "",
        });

        navigate("/home");
      } else {
        const errorData = await result.json();
        if (errorData?.deactivated) {
          setReactivate(errorData);
        }
        setformData({
          email: "",
          password: "",
        });
        if (errorData.remaining_attempts !== undefined) {
          toast.error(
            `${errorData?.error}. You have ${errorData?.remaining_attempts} remaining attempts.`
          );
        }
        else{
          toast.error(errorData.error);
        }
      }
      setLoading(false);
    } catch (error) {
      toast.warn(error?.response?.data?.error || "Incorrect Email Or Password");
      setformData({
        email: "",
        password: "",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center flex-col items-center h-screen overflow-hidden ">
      <div className="flex flex-row shadow-lg shadow-gray-300 rounded-lg mt-5 w-full max-w-[947px] h-auto lg:h-[580px]">
        <div
          className="hidden md:block w-1/3 animate-slideLeft text-white"
          style={{
            backgroundImage: "url('/assets/images/loginImage.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            zIndex: "998",
          }}
        >
          <div className="w-full h-3/4 flex flex-col text-center items-center justify-center text-white">
            <div className="w-10/12 text-4xl font-semibold">Welcome Back!</div>
            <div className="w-10/12 text-center">
              To keep connected with us, please log in.
            </div>
          </div>
          <div className="h-1/4 flex flex-col p-10 justify-end mt-2 text-center">
            <span className="account">
              Not have an account?{" "}
              <Link to="/register" className="font-semibold">
                Sign Up
              </Link>
            </span>
          </div>
        </div>
        <div
          className="hidden md:flex justify-center items-center transform -translate-x-1/2"
          style={{
            zIndex: "999",
          }}
        >
          <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center">
            <img
              src="/assets/images/LogoImongu.png"
              alt="imongu"
              className="w-10 h-10"
            />
          </div>
        </div>
        <div className="flex justify-center items-center px-4 w-full md:w-2/3">
          <div className="flex flex-col justify-around px-6 w-full">
            <h2 className="text-2xl font-semibold" style={{ color: "#347E96" }}>
              Sign In
            </h2>
            <form
              onSubmit={saveData}
              className="flex flex-col justify-center items-center login-form w-full"
            >
              <div style={{ marginTop: "10px" }} className="w-full">
                <label className="font-medium" style={{ color: "#347E96" }}>
                  Email Address
                </label>
                <br />
                <div style={{ position: "relative" }} className="w-full">
                  <svg
                    width="13"
                    height="11"
                    viewBox="0 0 13 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%", // Adjusted to center vertically
                      transform: "translateY(-50%)",
                      zIndex: "1",
                    }}
                  >
                    <path
                      d="M1.5 10.5C1.15625 10.5 0.861979 10.3776 0.617188 10.1328C0.372396 9.88802 0.25 9.59375 0.25 9.25V1.75C0.25 1.40625 0.372396 1.11198 0.617188 0.867188C0.861979 0.622396 1.15625 0.5 1.5 0.5H11.5C11.8438 0.5 12.138 0.622396 12.3828 0.867188C12.6276 1.11198 12.75 1.40625 12.75 1.75V9.25C12.75 9.59375 12.6276 9.88802 12.3828 10.1328C12.138 10.3776 11.8438 10.5 11.5 10.5H1.5ZM6.5 6.125L11.5 3V1.75L6.5 4.875L1.5 1.75V3L6.5 6.125Z"
                      fill="#AFB0B6"
                    />
                  </svg>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={changeHandeler}
                    value={formData.email}
                    placeholder="Enter Your Email Address"
                    required
                    style={{
                      paddingLeft: "30px",
                      paddingRight: "10px", // Ensure there's space for any potential icons on the right
                      height: "40px",
                      borderRadius: "5px",
                      backgroundColor: "#F8F9F9",
                      border: "none",
                      width: "100%", // Ensures the input takes full width
                    }}
                  />
                </div>
              </div>

              <div style={{ marginTop: "10px" }} className="w-full">
                <label className="font-medium" style={{ color: "#347E96" }}>
                  Password
                </label>
                <br />
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    onChange={changeHandeler}
                    value={formData.password}
                    placeholder="Enter Your Password"
                    required
                    style={{
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      height: "40px",
                      borderRadius: "5px",
                      backgroundColor: "#F8F9F9",
                      border: "none",
                      width: "100%", // Adjust input width if needed
                    }}
                  />
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "calc(50%)",
                      transform: "translateY(-25%)",
                      cursor: "pointer",
                    }}
                  >
                    <path
                      d="M1.99996 14.6667C1.63329 14.6667 1.3194 14.5361 1.05829 14.275C0.797182 14.0139 0.666626 13.7 0.666626 13.3333V6.66666C0.666626 6.29999 0.797182 5.9861 1.05829 5.72499C1.3194 5.46388 1.63329 5.33332 1.99996 5.33332H2.66663V3.99999C2.66663 3.07777 2.99163 2.29166 3.64163 1.64166C4.29163 0.991657 5.07774 0.666656 5.99996 0.666656C6.92218 0.666656 7.70829 0.991657 8.35829 1.64166C9.00829 2.29166 9.33329 3.07777 9.33329 3.99999V5.33332H9.99996C10.3666 5.33332 10.6805 5.46388 10.9416 5.72499C11.2027 5.9861 11.3333 6.29999 11.3333 6.66666V13.3333C11.3333 13.7 11.2027 14.0139 10.9416 14.275C10.6805 14.5361 10.3666 14.6667 9.99996 14.6667H1.99996ZM5.99996 11.3333C6.36663 11.3333 6.68052 11.2028 6.94163 10.9417C7.20274 10.6805 7.33329 10.3667 7.33329 9.99999C7.33329 9.63332 7.20274 9.31944 6.94163 9.05832C6.68052 8.79721 6.36663 8.66666 5.99996 8.66666C5.63329 8.66666 5.3194 8.79721 5.05829 9.05832C4.79718 9.31944 4.66663 9.63332 4.66663 9.99999C4.66663 10.3667 4.79718 10.6805 5.05829 10.9417C5.3194 11.2028 5.63329 11.3333 5.99996 11.3333ZM3.99996 5.33332H7.99996V3.99999C7.99996 3.44443 7.80552 2.97221 7.41663 2.58332C7.02774 2.19443 6.55552 1.99999 5.99996 1.99999C5.4444 1.99999 4.97218 2.19443 4.58329 2.58332C4.1944 2.97221 3.99996 3.44443 3.99996 3.99999V5.33332Z"
                      fill="#AFB0B6"
                    />
                  </svg>

                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "calc(50%)",
                      transform: "translateY(-25%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <path
                        d="M6 8C6.625 8 7.15625 7.78125 7.59375 7.34375C8.03125 6.90625 8.25 6.375 8.25 5.75C8.25 5.125 8.03125 4.59375 7.59375 4.15625C7.15625 3.71875 6.625 3.5 6 3.5C5.375 3.5 4.84375 3.71875 4.40625 4.15625C3.96875 4.59375 3.75 5.125 3.75 5.75C3.75 6.375 3.96875 6.90625 4.40625 7.34375C4.84375 7.78125 5.375 8 6 8ZM6 7.1C5.625 7.1 5.30625 6.96875 5.04375 6.70625C4.78125 6.44375 4.65 6.125 4.65 5.75C4.65 5.375 4.78125 5.05625 5.04375 4.79375C5.30625 4.53125 5.625 4.4 6 4.4C6.375 4.4 6.69375 4.53125 6.95625 4.79375C7.21875 5.05625 7.35 5.375 7.35 5.75C7.35 6.125 7.21875 6.44375 6.95625 6.70625C6.69375 6.96875 6.375 7.1 6 7.1ZM6 9.5C4.78333 9.5 3.675 9.16042 2.675 8.48125C1.675 7.80208 0.95 6.89167 0.5 5.75C0.95 4.60833 1.675 3.69792 2.675 3.01875C3.675 2.33958 4.78333 2 6 2C7.21667 2 8.325 2.33958 9.325 3.01875C10.325 3.69792 11.05 4.60833 11.5 5.75C11.05 6.89167 10.325 7.80208 9.325 8.48125C8.325 9.16042 7.21667 9.5 6 9.5Z"
                        fill="#AFB0B6"
                      />
                    ) : (
                      <path
                        d="M9.9 11.3L7.8 9.22499C7.50833 9.31666 7.21458 9.38541 6.91875 9.43124C6.62292 9.47708 6.31667 9.49999 6 9.49999C4.74167 9.49999 3.62083 9.15208 2.6375 8.45624C1.65417 7.76041 0.941667 6.85833 0.5 5.74999C0.675 5.30833 0.895833 4.89791 1.1625 4.51874C1.42917 4.13958 1.73333 3.79999 2.075 3.49999L0.7 2.09999L1.4 1.39999L10.6 10.6L9.9 11.3ZM6 7.99999C6.09167 7.99999 6.17708 7.99583 6.25625 7.98749C6.33542 7.97916 6.42083 7.96249 6.5125 7.93749L3.8125 5.23749C3.7875 5.32916 3.77083 5.41458 3.7625 5.49374C3.75417 5.57291 3.75 5.65833 3.75 5.74999C3.75 6.37499 3.96875 6.90624 4.40625 7.34374C4.84375 7.78124 5.375 7.99999 6 7.99999ZM9.65 8.22499L8.0625 6.64999C8.12083 6.50833 8.16667 6.36458 8.2 6.21874C8.23333 6.07291 8.25 5.91666 8.25 5.74999C8.25 5.12499 8.03125 4.59374 7.59375 4.15624C7.15625 3.71874 6.625 3.49999 6 3.49999C5.83333 3.49999 5.67708 3.51666 5.53125 3.54999C5.38542 3.58333 5.24167 3.63333 5.1 3.69999L3.825 2.42499C4.16667 2.28333 4.51667 2.17708 4.875 2.10624C5.23333 2.03541 5.60833 1.99999 6 1.99999C7.25833 1.99999 8.37917 2.34791 9.3625 3.04374C10.3458 3.73958 11.0583 4.64166 11.5 5.74999C11.3083 6.24166 11.0563 6.69791 10.7438 7.11874C10.4313 7.53958 10.0667 7.90833 9.65 8.22499ZM7.3375 5.92499L5.8375 4.42499C6.07083 4.38333 6.28542 4.40208 6.48125 4.48124C6.67708 4.56041 6.84583 4.67499 6.9875 4.82499C7.12917 4.97499 7.23125 5.14791 7.29375 5.34374C7.35625 5.53958 7.37083 5.73333 7.3375 5.92499Z"
                        fill="#AFB0B6"
                      />
                    )}
                  </svg>
                </div>
              </div>

              <div className="w-full my-2 text-right justify-end">
                <Link
                  to="/forget-password"
                  className="text-blue-600 hover:text-blue-500 underline"
                >
                  <span>Forgot password?</span>
                </Link>
                {reactivate && (
                  <div className="flex gap-x-2 justify-center items-center text-xs mt-2">
                    <p className=" text-xs">
                      Contact Admin for Reactivating your account . Click on the
                      button below
                    </p>
                    <button
                      className=" font-semibold text-sm bg-white border border-gray-300 hover:bg-gray-100 rounded-lg px-4 py-2"
                      onClick={handleReactivate}
                    >
                      Reactivate
                    </button>
                  </div>
                )}
              </div>

              <div className="w-full flex justify-center items-center">
                <button
                  className="font-semibold flex items-center justify-center text-center py-2 px-0 rounded-md outline-none text-sm  border-none w-1/2 text-white cursor-pointer mt-2 hover:bg-button-hover bg-[#FC9334]"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <img
                      src="/assets/images/WhiteLoader.svg"
                      className="w-8 h-8"
                    />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
              <span className="account block md:hidden mt-2">
                Not have an account?{" "}
                <Link to="/register" className="font-semibold">
                  Sign Up
                </Link>
              </span>
              <span className="w-full flex  justify-center items-center my-2 mx-1">
                -OR-
              </span>
              <div className="flex justify-center w-full my-2 mx-1">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleAuthLogin(credentialResponse?.credential, "google");
                  }}
                  text="signin_with"
                  useOneTap
                />
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
