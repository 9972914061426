import React from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  BarChart,
} from "recharts";

const ObjectiveChart = ({ objChartRef, barData, stackedBarData }) => {
  if (!barData.length || !stackedBarData.length)
    return (
      <div className="text-gray-500 text-center mt-2">No data Available.</div>
    );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "10px",
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ margin: 0, color: entry.fill }}>
              {entry.name}: {entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const CustomLegend = ({ barData }) => {
    return (
      <div className="flex gap-4 mt-2 justify-center">
        {barData.map((bar, index) => (
          <div
            key={index}
            className="flex"
          >
            <span
            className="w-4 h-4 rounded-full mt-1"
              style={{ backgroundColor: bar.fill }}
            ></span>
            <span className="text-gray-500 ml-2 text-sm">{bar.name}<br/>(%)</span>{" "}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div
        className="charts-wrapper bg-white  gap-8 my-4 p-3"
        ref={objChartRef}
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={stackedBarData}
            stackOffset="expand"
            style={{ background: "transparent" }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#ddd" />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 12, fill: "#555" }}
              axisLine={{ stroke: "#ccc" }}
              tickLine={{ stroke: "#ccc" }}
            />
            <YAxis
              tick={{ fontSize: 12, fill: "#555" }}
              axisLine={{ stroke: "#ccc" }}
              tickLine={{ stroke: "#ccc" }}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "transparent" }}
            />
            {barData.map((bar, index) => (
              <Bar
                key={index}
                dataKey={bar.dataKey}
                stackId="a"
                fill={bar.fill}
                name={bar.name}
                barSize={20}
                isAnimationActive={false}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
        {/* Custom Legend */}
        <CustomLegend barData={barData} />
      </div>
    </div>
  );
};

export default ObjectiveChart;
