import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Box,
  HStack,
  Text,
  IconButton,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoClose } from "react-icons/io5";
import { FaInfoCircle, FaLightbulb, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import CommentSectionTemplate from "./CommentSectionTemplate";
import { Flex, Tooltip } from "antd";
import zIndex from "@mui/material/styles/zIndex";

const TemplateModal = ({
  setTemplateModal,
  template,
  description: initialDescription,
  templateModal,
  data,
  primaryColor,
  secondaryColor,
  handleUpdateTemplate,
  title: initialTitle,
  readOnly = "false",
  handleSchedule,
}) => {
  const initialSections = data?.map((item) => ({
    question_title: item?.question_title,
    questions: item?.question_list?.map((q) => ({
      text: q.text,
      answer: q.answer,
    })),
  }));
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state.user);
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const dummyComments = [
    {
      id: 1,
      username: "John Doe",
      email: "johndoe@example.com",
      profile_image: "",
      comment: "<p>This is a great post! Thanks for sharing.</p>",
    },
    {
      id: 2,
      username: "Jane Smith",
      email: "janesmith@example.com",
      profile_image: "",
      comment: "<p>Interesting insights! I totally agree with you.</p>",
    },
  ];
  const [comments, setComments] = useState(dummyComments);
  const [newComment, setNewComment] = useState(null);
  const [sections, setSections] = useState(initialSections);
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(null);
  const [isScheduleDisabled, setIsScheduleDisabled] = useState(null);

  useEffect(() => {
    if (template?.comment_text && template?.comment_text !== "") {
      setComments((prevComments) => [
        ...prevComments,
        {
          id: currentUser?.id,
          username: currentUser?.username,
          email: currentUser?.email,
          comment: template?.comment_text,
          avatar: currentUser?.profile_image,
        },
      ]);
    }
  }, []);

  const handleSectionTitleChange = (index, value) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[index].question_title = value;
      setSections(updatedSections);
    }
  };

  const handleQuestionChange = (sectionIndex, questionIndex, field, value) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[sectionIndex].questions[questionIndex][field] = value;
      setSections(updatedSections);
    }
  };

  const handleDeleteSection = (sectionIndex) => {
    if (!readOnly) {
      const updatedSections = sections.filter((_, idx) => idx !== sectionIndex);
      setSections(updatedSections);
    }
  };

  const handleDeleteQuestion = (sectionIndex, questionIndex) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[sectionIndex].questions = updatedSections[
        sectionIndex
      ].questions.filter((_, idx) => idx !== questionIndex);
      setSections(updatedSections);
    }
  };

  const handleAddQuestion = (sectionIndex) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[sectionIndex].questions.push({ text: "", answer: "" });
      setSections(updatedSections);
    }
  };

  const handleAddSection = () => {
    if (!readOnly) {
      setSections([
        ...sections,
        {
          question_title: "",
          questions: [{ text: "", answer: "" }],
        },
      ]);
    }
  };

  const handleTitleChange = (e) => {
    if (!readOnly) {
      setTitle(e.target.value);
      setIsTitleChanged(e.target.value !== initialTitle);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setIsDescriptionChanged(e.target.value !== initialDescription);
  };

  useEffect(() => {
    const areSectionsChanged = () => {
      return sections.some((section, sectionIndex) => {
        const initialSection = initialSections[sectionIndex];

        // If there's no initial section, treat it as changed
        if (!initialSection) return true;

        // Check if the question title has changed
        if (section.question_title !== initialSection.question_title) {
          return true;
        }

        return section.questions.some((question, questionIndex) => {
          const initialQuestionText =
            initialSection.questions[questionIndex]?.text;
          return question.text !== initialQuestionText;
        });
      });
    };

    const saveDisabled =
      readOnly ||
      !title?.trim() ||
      !isTitleChanged ||
      sections.some(
        (section) =>
          !section?.question_title?.trim() ||
          section?.questions?.some((question) => !question?.text?.trim())
      );
    const saveDisabled2 =
      readOnly ||
      !title?.trim() ||
      sections.some(
        (section) =>
          !section?.question_title?.trim() ||
          section?.questions?.some((question) => !question?.text?.trim())
      );

    const scheduleDisabled =
      readOnly ||
      isTitleChanged ||
      isDescriptionChanged ||
      areSectionsChanged();

    setIsScheduleDisabled(scheduleDisabled);
    if (template?.default_temp) {
      setIsSaveDisabled(saveDisabled);
    } else {
      setIsSaveDisabled(saveDisabled2);
    }
  }, [
    sections,
    title,
    isTitleChanged,
    isDescriptionChanged,
    readOnly,
    template?.default_temp,
  ]);

  const handleSave = () => {
    if (!readOnly) {
      setLoading(true);

      const payload = {
        company_id: currentEmployee?.company_id,
        template_id: template?.id,
        description: description,
        template_title: title,
        template_type: template?.template_type,
        questions: sections?.map((section) => ({
          question_title: section?.question_title,
          question_list: section?.questions?.map((question) => ({
            text: question?.text,
            answer: question?.answer,
          })),
        })),
      };

      handleUpdateTemplate(payload);

      setTimeout(() => {
        setLoading(false);
        setTemplateModal(false);
      }, 1000);
    }
  };

  return (
    <Modal
      isOpen={templateModal}
      onClose={() => setTemplateModal(false)}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent h="80vh">
        <ModalHeader
          bg={primaryColor}
          borderTopLeftRadius="md"
          borderTopRightRadius="md"
          style={{
            backgroundColor: `${secondaryColor}`,
          }}
        >
          <Input
            value={title}
            onChange={handleTitleChange}
            placeholder="Enter title..."
            border="none"
            boxShadow="none"
            _focus={{ border: "none", boxShadow: "none" }}
            backgroundColor="transparent"
            color="#4F5762"
            fontSize="2xl"
            fontWeight="semibold"
            className="outline-none"
            readOnly={readOnly}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY="auto"
          maxH="calc(80vh - 120px)"
          p={6}
          backgroundColor={primaryColor}
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <div>
            <label className="text-md">Description</label>
            <Textarea
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter Description..."
              border="none"
              boxShadow="none"
              _focus={{ border: "none", boxShadow: "none" }}
              backgroundColor="transparent"
              className="outline-none"
              readOnly={readOnly}
              resize="none"
            />
          </div>
          <div className="mb-6">
            {sections?.map((section, sectionIndex) => (
              <div key={sectionIndex} className="mb-4">
                <div className="flex items-center justify-between mb-4">
                  <input
                    type="text"
                    value={section?.question_title}
                    onChange={(e) =>
                      handleSectionTitleChange(sectionIndex, e.target.value)
                    }
                    className="text-lg font-lato bg-transparent font-bold border-none text-black outline-none w-full"
                    placeholder="Enter section title..."
                    readOnly={readOnly}
                  />
                  {!readOnly && (
                    <IconButton
                      icon={<IoClose />}
                      backgroundColor={"transparent"}
                      _hover={{ backgroundColor: "transparent" }}
                      onClick={() => handleDeleteSection(sectionIndex)}
                      size="sm"
                    />
                  )}
                </div>
                <ol className="list-decimal pl-6 space-y-4 text-gray-700">
                  {section?.questions?.map((question, questionIndex) => (
                    <>
                      <li key={questionIndex} className="flex ">
                        <p className="flex-shrink-0 mr-2 flex items-start justify-center">
                          {questionIndex + 1}.
                        </p>
                        <textarea
                          value={question?.text}
                          onChange={(e) =>
                            handleQuestionChange(
                              sectionIndex,
                              questionIndex,
                              "text",
                              e.target.value
                            )
                          }
                          className="border-none bg-transparent mt-0 w-full flex flex-wrap overflow-hidden items-center justify-center  "
                          placeholder="Enter question..."
                          readOnly={readOnly}
                        />

                        {!readOnly && (
                          <IconButton
                            icon={<IoClose />}
                            backgroundColor={"transparent"}
                            _hover={{ backgroundColor: "transparent" }}
                            onClick={() =>
                              handleDeleteQuestion(sectionIndex, questionIndex)
                            }
                            size="sm"
                            ml={2}
                          />
                        )}
                      </li>
                      {readOnly && (
                        // <span className=" w-full p-2 rounded">
                        //   {question?.answer}
                        // </span>
                        <ReactQuill
                          value={question?.answer}
                          readOnly={true}
                          className="my-2 w-full border border-gray-200 bg-gray-100 rounded text-base leading-relaxed"
                          style={{
                            minHeight: "100px",
                            maxHeight: "100px",
                            height: "100px",
                            overflowY: "auto", // Enable vertical scrolling
                          }}
                          modules={{
                            toolbar: false, // Disable toolbar in read-only mode
                          }}
                        />
                      )}
                    </>
                  ))}
                </ol>
                {!readOnly && (
                  <Button
                    leftIcon={<FaPlus />}
                    onClick={() => handleAddQuestion(sectionIndex)}
                    backgroundColor={secondaryColor}
                    textColor={"#4F5762"}
                    px={"28px"}
                    borderRadius={"20px"}
                    size="sm"
                    mt={2}
                  >
                    Add Question
                  </Button>
                )}
              </div>
            ))}
            {!readOnly && (
              <Button
                leftIcon={<FaPlus />}
                onClick={handleAddSection}
                backgroundColor={secondaryColor}
                textColor={"#4F5762"}
                px={"28px"}
                borderRadius={"20px"}
                size="sm"
                mt={2}
              >
                Add Section
              </Button>
            )}
          </div>
          <hr />
          {readOnly ? (
            <>
              <CommentSectionTemplate data={template} />
            </>
          ) : (
            <Box mt={4}>
              <Flex className="justify-between">
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                  Comment Section
                </Text>
                <Tooltip
                  title="You can comment after creating a Schedule"
                  overlayStyle={{ zIndex: 9999 }}
                  placement="top"
                >
                  <FaInfoCircle
                    className="mr-2"
                    size={26}
                    style={{ zIndex: 9999 }}
                  />
                </Tooltip>
              </Flex>
              <Box maxH="300px" overflowY="auto" mb={4}>
                {comments?.map((comment) => (
                  <HStack key={comment?.id} alignItems="flex-start" mb={4}>
                    <Box className="grid grid-cols-6 space-x-2 w-full">
                      <Box className="flex col-span-2  justify-center items-center space-x-2">
                        <div
                          className="w-6 h-6 border text-sm border-black rounded-full flex items-center justify-center text-white  relative"
                          style={{
                            backgroundColor: comment?.profile_image
                              ? "initial"
                              : getAvatarColor(comment?.username),
                          }}
                          size={30}
                        >
                          {comment?.profile_image ? (
                            <img
                              src={comment?.profile_image}
                              className="w-full h-full object-cover rounded-full"
                              alt="Profile"
                            />
                          ) : (
                            getInitials(comment?.username)
                          )}
                        </div>
                        <Box className="flex-col  items-end justify-center">
                          <Text fontWeight="bold">{comment?.username}</Text>
                          <Text fontSize="xs" color="gray.500">
                            {comment?.email}
                          </Text>
                        </Box>
                      </Box>
                      <Box className="col-span-4 ">
                        <ReactQuill
                          value={comment?.comment} // Set the comment as the value
                          readOnly={true} // Make it read-only
                          modules={{
                            toolbar: false,
                          }}
                          className="w-full rounded-md bg-white text-sm"
                          style={{
                            padding: "20px",
                            backgroundColor: "white",
                            boxShadow: "3px 4px 24px 0px rgba(0, 0, 0, 0.05)",
                            height: "auto", // Set height to auto for flexible content
                          }}
                        />
                      </Box>
                    </Box>
                  </HStack>
                ))}
              </Box>
            </Box>
          )}
        </ModalBody>
        <ModalFooter
          position="sticky"
          bottom={0}
          bg="white"
          borderTop="1px solid #E2E8F0"
          boxShadow="0px -4px 8px -4px rgba(0, 0, 0, 0.1)"
          p={4}
        >
          {!readOnly && (
            <Button
              variant="outline"
              borderRadius={"20px"}
              mr={3}
              px={"28px"}
              onClick={() => {
                setTemplateModal(false);
                handleSchedule();
              }}
              isDisabled={isScheduleDisabled}
            >
              New Schedule
            </Button>
          )}
          <Button
            variant="outline"
            borderRadius={"20px"}
            mr={3}
            px={"28px"}
            onClick={() => setTemplateModal(false)}
          >
            Cancel
          </Button>
          {!readOnly && (
            <Tooltip
              arrow={false}
              placement="rightTop"
              zIndex={10500}
              className="mr-5"
              title={
                loading || isSaveDisabled
                  ? "Ensure all fields are filled, and the template title has been updated"
                  : ""
              }
            >
              <button
                style={{
                  backgroundColor: "#FFF1E3",
                  color: "#F27E0D",
                  padding: "8px",
                  borderRadius: "20px",
                  border: "none",
                  cursor: loading || isSaveDisabled ? "not-allowed" : "pointer", // Change cursor style when disabled
                  opacity: loading || isSaveDisabled ? 0.5 : 1, // Lower opacity when disabled
                  position: "relative",
                }}
                onClick={() => {
                  handleSave();
                }}
                disabled={loading || isSaveDisabled}
                aria-label={
                  template?.default_temp ? "Save as New Template" : "Save"
                }
                aria-disabled={loading || isSaveDisabled}
              >
                {template?.default_temp ? "Save as New Template" : "Save"}
              </button>
            </Tooltip>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TemplateModal;
