import React, { useState } from "react";
import { Box, Checkbox, CheckboxGroup, FormControl } from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../components/global/api";
import { Link } from "react-router-dom";
import CustomScrollbar from "../../components/global/CustomScrollBar";

function ContactUsForm() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    companyName: "",
    message: "",
    agreedTerms: false,
  });

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && !value.includes("e")) {
      const trimmedValue = value.slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        [name]: trimmedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = () => {
    // setIsChecked(!isChecked);
    setFormData((prevData) => ({
      ...prevData,
      agreedTerms: !formData?.agreedTerms,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(formData.phoneNumber)) {
      toast.error("Please Enter A Valid Phone Number With 10 Digits");
      return;
    }

    if (!validateEmail(formData.emailAddress)) {
      toast.error("Please Enter A Valid Email Address");
      return;
    }

    if (formData?.agreedTerms) {
      try {
        setLoading(true);
        const res = await api.post("/contact/", {
          name: formData?.fullName,
          content: formData?.message,
          phone_number: formData?.phoneNumber,
          company_name: formData?.companyName,
          email: formData?.emailAddress,
        });
        setLoading(false);
        setFormData({
          fullName: "",
          emailAddress: "",
          phoneNumber: "",
          companyName: "",
          message: "",
          agreedTerms: false,
        });
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Please Try Again Later", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <Box className="overflow-y-auto">
      <CustomScrollbar>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full sm:w-11/12 p-2 justify-center rounded-2xl bg-white"
          style={{
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="col-span-1 p-2">
            <div className="flex flex-col justify-around px-2 w-full rounded-md border">
              <img
                loading="lazy"
                decoding="asynchronous"
                src="/assets/images/ContactUs.png"
                alt="Contact Us"
                className="mb-2"
                height="50px"
                style={{ transform: "rotate(270deg)" }}
              />
              <img
                loading="lazy"
                decoding="asynchronous"
                src="/assets/images/imongu2.svg"
                alt="iMongu Logo"
                height="30px"
                width="80px"
                className="mb-2"
              />
              <p className="text-sm text-gray-600">
                Master OKRs & Achieve More! <br /> 🚀 Get Early Access! iMongu
                is unveiling a cutting-edge OKR curriculum designed to help you
                master OKR methodology, set clear goals and drive real results.
                Sign up now for expert insights and early enrollment
                opportunities!
              </p>
            </div>
          </div>

          <div className="col-span-1 sm:col-span-2 lg:col-span-2 w-full p-4">
            <div className="flex flex-col justify-around font-poppins w-full">
              <div className="flex flex-col justify-between w-full">
                <p className="text-lg text-[#347E96]">Let's Talk</p>
                <p className="font-medium text-4xl w-full">
                  Get In Touch With Us
                </p>
                <p className="font-light text-sm text-[#595F66]">
                  Do you have any questions, suggestions, or feedback? We’d love
                  to hear from you!
                </p>
              </div>

              <div className="flex flex-col justify-between w-full">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-2 w-full">
                    <input
                      placeholder="Full Name"
                      type="text"
                      name="fullName"
                      required
                      value={formData.fullName}
                      onChange={handleChange}
                      className="w-full"
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "rgba(89, 95, 102, 0.04)",
                      }}
                    />
                    <input
                      placeholder="Email Address"
                      type="email"
                      name="emailAddress"
                      className="w-full"
                      required
                      value={formData.emailAddress}
                      onChange={handleChange}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "rgba(89, 95, 102, 0.04)",
                      }}
                    />
                    <input
                      placeholder="Phone Number"
                      required
                      type="number"
                      pattern="[0-9]*"
                      name="phoneNumber"
                      className="w-full"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "rgba(89, 95, 102, 0.04)",
                      }}
                    />
                    <input
                      placeholder="Company Name"
                      required
                      name="companyName"
                      value={formData.companyName}
                      className="w-full"
                      onChange={handleChange}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "rgba(89, 95, 102, 0.04)",
                      }}
                    />
                  </div>

                  <textarea
                    rows={4}
                    placeholder="Type Your Message..."
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="mt-4 px-2 py-1 text-sm w-full focus:outline-none focus-visible:border-gray-500"
                    style={{
                      backgroundColor: "rgba(89, 95, 102, 0.04)",
                      border: "1px solid transparent",
                      borderRadius: "5px",
                    }}
                  />

                  <FormControl
                    style={{
                      marginTop: "8px",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <CheckboxGroup
                      defaultValue={["Label"]}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: "400",
                          marginRight: "5px",
                          marginTop: "4px",
                        }}
                        isChecked={formData?.agreedTerms}
                        onChange={handleCheckboxChange}
                      />
                      <span style={{ color: "#595F66", opacity: "70%" }}>
                        I agree to the{" "}
                      </span>
                      <span style={{ color: "#347E96" }}>
                        <Link
                          to="/termsofservices"
                          style={{
                            textDecoration: "none",
                            color: "#347E96",
                          }}
                        >
                          Terms Of Services
                        </Link>
                      </span>{" "}
                      <span
                        style={{
                          color: "#595F66",
                          opacity: "70%",
                          marginLeft: "2px",
                        }}
                      >
                        and
                      </span>{" "}
                      <span style={{ color: "#347E96" }}>
                        <Link
                          to="/privacypolicy"
                          style={{
                            textDecoration: "none",
                            color: "#347E96",
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </span>
                    </CheckboxGroup>
                  </FormControl>

                  <div className="flex justify-end mt-4">
                    <button
                      disabled={!formData?.agreedTerms}
                      className={`bg-button text-white hover:bg-button-hover flex justify-center items-center w-full sm:w-1/3 py-4 rounded-2xl ${
                        !formData?.agreedTerms ? "cursor-not-allowed" : ""
                      }`}
                    >
                      {loading ? (
                        <img
                          loading="lazy"
                          decoding="asynchronous"
                          src="/assets/images/WhiteLoader.svg"
                          className="w-8 h-8"
                        />
                      ) : (
                        "Send a message"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </CustomScrollbar>
    </Box>
  );
}

export default ContactUsForm;
