import React from "react";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  RadialBarChart,
  RadialBar,
} from "recharts";

const COLORS = ["#266EEB", "#2CC9A2", "#9061F2"];

const QuarterlyProgress = ({ data, quarterlyRef, contributeRef }) => {
  const Sessions = useSelector((state) => state.user?.sessionList);

  const progressData = Object.keys(data?.key_quarter_progress || {}).map(
    (quarter) => {
      const progress = data?.key_quarter_progress[quarter];
      const matchingQuarter = Sessions?.find((q) => q.value === quarter);
      return {
        quarter: matchingQuarter?.label || 0,
        completed: progress?.completed || 0,
        completed_percentage: progress?.completed_percentage || 0,
        in_progress_percentage: progress?.in_progress_percentage || 0,
        not_started_percentage: progress?.not_started_percentage || 0,
        inProgress: progress?.in_progress || 0,
        notStarted: progress?.not_started || 0,
      };
    }
  );

  // Transform data for RadialBarChart
  const getChartData = (item) => [
    {
      name: "Completed",
      value: item.completed_percentage,
      fill: COLORS[0],
    },
    {
      name: "In Progress",
      value: item.in_progress_percentage,
      fill: COLORS[1],
    },
    {
      name: "Not Started",
      value: item.not_started_percentage,
      fill: COLORS[2],
    },
  ];

  // Transform data for BarChart
  const barChartData = Object.keys(data?.individual_or_team_goals || {}).map(
    (quarter) => {
      const goals = data?.individual_or_team_goals[quarter];
      const matchingQuarter = Sessions?.find((q) => q.value === quarter);
      return {
        quarter: matchingQuarter?.label || 0,
        individual: goals?.individual_goals || 0,
        team: goals?.team_goals || 0,
      };
    }
  );

  const CustomRadialLegend = ({ item }) => {
    const legendData = [
      { name: "Completed", color: COLORS[0], value: item.completed_percentage },
      {
        name: "In Progress",
        color: COLORS[1],
        value: item.in_progress_percentage,
      },
      {
        name: "Not Started",
        color: COLORS[2],
        value: item.not_started_percentage,
      },
    ];

    return (
      <div className="flex gap-4 mt-4 justify-center">
        {legendData.map((legend, index) => (
          <div
            key={index}
            className="inline-flex items-center mr-6 mb-2 text-base"
          >
            <span
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: legend.color }}
            ></span>
            <span className="text-sm text-gray-700 mt-1 ml-2">
              {legend.name}
              <br /> ({legend.value} %)
            </span>
          </div>
        ))}
      </div>
    );
  };

  const CustomLegend = ({ item }) => {
    const legendData = [
      { name: "Individual Goals", color: "#2CC9A2" },
      { name: "Team Goals", color: "#266EEB" },
    ];

    return (
      <div className="flex gap-4 mx-auto mt-4 justify-center">
        {legendData.map((legend, index) => (
          <div
            key={index}
            className="flex"
          >
            <span
              className="w-4 h-4 rounded-full mt-1"
              style={{ backgroundColor: legend.color }}
            ></span>
            <span className="text-sm text-gray-700 ml-2">
              {legend.name}
              <br /> Count Quarter Wise
            </span>
          </div>
        ))}
      </div>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border border-gray-200 rounded shadow">
          <p className="text-sm">{`${payload[0].name}: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6" ref={quarterlyRef}>
        {progressData.map((item, index) => (
          <div
            key={index}
            className="bg-white border border-gray-200 rounded-md p-4"
          >
            <div className="text-lg font-bold mb-2 text-center">
              {item.quarter}
            </div>

            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <RadialBarChart
                  cx="50%"
                  cy="50%"
                  innerRadius="30%"
                  outerRadius="80%"
                  barSize={15}
                  data={getChartData(item)}
                  startAngle={90}
                  endAngle={90 + 360}
                >
                  <RadialBar
                    minAngle={15}
                    background
                    clockWise
                    dataKey="value"
                    label={{
                      position: "insideStart",
                      fill: "#fff",
                      formatter: (value, entry, index) => {
                        // Only show percentage for the actual value (not the background)
                        if (index === 2) return `${item.completed_percentage}%`;
                        if (index === 1)
                          return `${item.in_progress_percentage}%`;
                        if (index === 0)
                          return `${item.not_started_percentage}%`;
                        return "";
                      },
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                </RadialBarChart>
              </ResponsiveContainer>
            </div>
            <CustomRadialLegend item={item} />
          </div>
        ))}
      </div>

      {/* Individual vs Team Goals Chart */}
      <div
        className="bg-white border border-gray-200 rounded-md p-6 mt-6"
        ref={contributeRef}
      >
        <div className="text-lg font-bold mb-4 text-center">
          Goals by Individual and Team
        </div>

        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={barChartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
              style={{ background: "transparent" }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="quarter"
                tick={{ fill: "#555", fontSize: 12 }}
                axisLine={{ stroke: "#ccc" }}
              />
              <YAxis />
              <Tooltip cursor={{ fill: "transparent" }} />
              <Bar
                dataKey="individual"
                fill="#2CC9A2"
                name="Individual Goals"
                barSize={20}
              />
              <Bar
                dataKey="team"
                fill="#266EEB"
                name="Team Goals"
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <CustomLegend />
      </div>
    </div>
  );
};

export default QuarterlyProgress;
