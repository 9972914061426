import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import { BiGlobe } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
} from "../../../stores/slices/okrSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../../utils/helpers";

const ExecutiveInsightsTable = ({ data, type }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  const handleOpenDrawerWithKey = (okr_id) => {
    if (user?.roleAccess?.Objectives?.View) {
      dispatch(handleOpenOkrDrawer(okr_id));
    }
  };

  const handleOpenDrawerWithKey1 = (key_id) => {
    if (user?.roleAccess?.["Key-Results"]?.View) {
      dispatch(handleOpenKeyresultDrawer(key_id));
    }
  };

  const handleOpenDrawerWithKey2 = (goal_id) => {
    if (user?.roleAccess?.Goals?.View) {
      dispatch(handleOpenGoalDrawer(goal_id));
    }
  };

  return (
    <div className="w-full h-full p-2">
      <div className="flex justify-between items-center mb-5">
        <h2 className="text-lg font-medium text-basic">
          All{" "}
          {type === "Objective"
            ? "Objectives"
            : type === "Goal"
            ? "Goals"
            : "Key Results"}
        </h2>
      </div>

      <table className="w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-header text-white">
          <tr>
            <th className="py-3 px-4 text-left text-lg font-medium border border-gray-300">
              Title
            </th>
            <th className="py-3 px-4 text-left text-lg font-medium border border-gray-300">
              Owner(s)
            </th>
            <th className="py-3 px-4 text-left text-lg font-medium border border-gray-300">
              Progress
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr key={i} className="border border-gray-300 hover:bg-gray-100">
              {/* Title Column */}
              <td className="py-4 px-2 text-basic font-medium break-words">
                {item?.title}
              </td>

              {/* Owner(s) Column */}
              <td className="py-4 px-4">
                <ul className="space-y-2">
                  {item?.owners?.map((owner, index) => (
                    <li key={index} className="flex items-center gap-x-2">
                      {owner?.type === "user" ? (
                        <div
                          className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white"
                          style={{
                            backgroundColor: owner?.profile_image
                              ? "initial"
                              : getAvatarColor(owner?.username),
                          }}
                        >
                          {owner?.profile_image ? (
                            <img
                              src={owner?.profile_image}
                              alt="Profile"
                              className="w-full h-full object-cover rounded-full"
                            />
                          ) : (
                            getInitials(owner.name)
                          )}
                        </div>
                      ) : (
                        <span className="p-1 bg-gray-600 rounded-full w-5 h-5 flex items-center justify-center">
                          <BiGlobe className="text-gray-300 text-xs" />
                        </span>
                      )}
                      <span>{owner?.name}</span>
                    </li>
                  ))}
                </ul>
              </td>

              {/* Progress Column */}
              <td className="py-4 px-4">
                <Progress
                  percent={item?.overall_gain}
                  strokeColor={
                    parseInt(item?.overall_gain) < 100 ? "#F77C33" : "#0AA959"
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default ExecutiveInsightsTable;
