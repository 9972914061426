import React from "react";
import {
  Box,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  Button,
  Flex,
  Image,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const Feature = ({ iconSrc, text, fontSize = "md" }) => (
  <Flex alignItems={"center"}>
    <Image loading="lazy" decoding="asynchronous" src={iconSrc} w={3} h={3} />
    <Text fontWeight={400} marginLeft={2} fontSize={fontSize} color={"#FFFFFF"}>
      {text}
    </Text>
  </Flex>
);

export default function CTA() {
  const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate("/contactus");
  };

  return (
    <>
      <Box px={["4", "8", "12", "16"]}>
        <Stack
          bg={useColorModeValue("#011824")}
          py={["12", "16"]}
          px={["6", "8", "10", "12"]}
          my={["10", "12", "16"]}
          spacing={{ base: 8, md: 4 }}
          align={"center"}
          borderRadius="xl"
        >
          <Heading color={"#FFFFFF"} fontSize={["2xl", "3xl", "4xl", "5xl"]}>
            Ready To Experience iMongu?
          </Heading>
          <Text
            fontSize={{ base: "xl", md: "xl" }}
            textAlign={"center"}
            maxW={"2xl"}
            color={"#FFFFFFA6"}
          >
            Take a No-Obligation Tour Of iMongu. Explore Features And Get a
            Tailormade Consultation. Discover What Works Best For Your Business.
          </Text>
          <Box textAlign={"center"} mb={4}>
            <Flex gap={4} alignItems={"center"} direction={["column", "row"]}>
              <Button
                bg="#FFFFFF"
                color="#011824"
                size={["md", "lg"]}
                to="/contactus"
                onClick={handleDemoClick}
                _hover={{
                  bg: "#e0dada"
                }}
                rightIcon={<ArrowForwardIcon />}
              >
                Book A Demo
              </Button>
              <Button
                color="#FFFFFF"
                size={["md", "lg"]}
                to="/register"
                variant="outline"
                gap={1}
                borderColor="rgba(255, 255, 255, 0.4)"
                _hover={{ bg: "rgba(255, 255, 255, 0.4)" }}
                onClick={() => {
                  document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                View Pricing
              </Button>
            </Flex>

            <Flex gap={4} alignItems={"center"} mt={4}>
              <Feature
                iconSrc="/assets/images/CIcon.svg"
                text="14 Days Free Trial"
              />
              <Feature
                iconSrc="/assets/images/CIcon.svg"
                text="No Credit Card Required"
                fontSize="sm"
              />
            </Flex>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
