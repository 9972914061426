import React, { useEffect, useRef, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import Tree from "react-d3-tree";
import { ArrowRightAlt } from "@mui/icons-material";
import ModalContentComponent from "./ModalContentComponent";
import { baseURL } from "../../utils/config";
import { api } from "../../components/global/api";
import { useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getAvatarColor, getInitials } from "../../utils/helpers";

const OrganizationHierarchy = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [treeData, setTreeData] = useState(null);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const treeContainerRef = useRef(null);

  const fetchTreeData = async () => {
    try {
      const res = await api.get(`${baseURL}/hierarchy/`, {
        company_id: currentUser?.employees?.[user?.currentCompany]?.company_id,
      });
      if (res?.data && Array.isArray(res.data) && res.data.length > 0) {
        const formattedData = formatHierarchyData(res.data);
        setTreeData(formattedData);
      } else {
        console.error("Unexpected data structure:", res?.data);
      }
    } catch (error) {
      console.error("Error fetching tree data:", error);
    }
  };

  useEffect(() => {
    fetchTreeData();
  }, []);

  useEffect(() => {
    // Center the tree initially based on the container dimensions
    if (treeContainerRef.current) {
      const { width, height } =
        treeContainerRef.current.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 4 }); // Adjust `y` as needed for vertical centering
    }
  }, [treeData]);

  // Modal control functions
  const openModal = (nodeDatum) => {
    setSelectedNode(nodeDatum);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [visibleNodes, setVisibleNodes] = useState(2);

  const renderCustomNode = ({ nodeDatum }) => {
    if (!nodeDatum) return null;

    return (
      <g>
        {/* Main container with grey background */}
        <foreignObject x="-160" y="-110" width="350" height="260">
          {" "}
          {/* Adjusted x, y, and height */}
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: "#F7F8FA", // Grey background color
              borderRadius: "16px",
              paddingTop: "30px",
              boxSizing: "border-box",
            }}
          >
            {/* Card container */}
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                // border: "1px solid #E2E8F0",
                borderRadius: "16px",
                backgroundColor: "#FFFFFF",
                paddingTop: "70px",
                textAlign: "center",
              }}
            >
              {/* Profile Image or Initials */}
              <div
                style={{
                  position: "absolute",
                  top: "-30px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 2,
                }}
              >
                {nodeDatum.user_profile?.profile_image ? (
                  <img
                    src={nodeDatum.user_profile.profile_image}
                    alt="Profile"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      backgroundColor: getAvatarColor(
                        nodeDatum?.user_profile?.username
                      ),
                      color: "#fff",
                      fontSize: "24px",
                      fontWeight: "bold",
                      border: "4px solid #FFFFFF",
                    }}
                  >
                    {getInitials(nodeDatum?.user_profile?.username)}
                  </div>
                )}
              </div>

              {/* User Info */}
              <div style={{ marginTop: "30px" }}>
                <h3
                  style={{
                    margin: "10px 0 5px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {nodeDatum.user_profile?.username || "Unknown"}
                </h3>
                <p
                  style={{
                    color: "#888",
                    margin: "0 0 20px",
                    fontSize: "16px",
                  }}
                >
                  {nodeDatum.role_name || "Unknown"}
                </p>
              </div>

              {/* Goals button */}
              <div
                onClick={() => openModal(nodeDatum)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  borderTop: "1px solid #E2E8F0",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
              >
                <span>Goals</span>
                <span style={{ fontSize: "20px", color: "#888" }}>&rarr;</span>
              </div>
            </div>
          </div>
        </foreignObject>
      </g>
    );
  };

  const formatHierarchyData = (data) => {
    return data?.map((item) => ({
      ...item,
      children: item?.children ? formatHierarchyData(item?.children) : [],
    }));
  };

  const [currentPage, setCurrentPage] = useState(0); // Starts from 0 for alignment with treeData

  const treeIndex = currentPage;
  const isValidIndex = treeIndex >= 0 && treeIndex < treeData?.length;

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < treeData?.length) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div ref={treeContainerRef} className="overflow-y-auto h-full w-full">
      <CustomScrollbar>
        <div className="p-4 rounded-lg w-full space-y-2  font-sans">
          <div
            className="flex items-center justify-between  "
            style={{ height: "75vh" }}
          >
            {/* Left Button */}
            {currentPage > 0 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="pagination-button flex justify-center items-center"
                aria-label="Go to previous page"
              >
                <FaChevronLeft color="#818589" size={32} />
              </button>
            )}

            {/* Tree Component */}
            <div
              style={{ width: "80vw", height: "100%" }}
              className="flex justify-center items-center"
            >
              {isValidIndex ? (
                <Tree
                  data={treeData[currentPage]} // Correct index usage
                  renderCustomNodeElement={renderCustomNode}
                  orientation="vertical"
                  translate={translate}
                  pathFunc="step"
                  zoom={"0.6"}
                  separation={{ siblings: 2, nonSiblings: 2 }}
                  nodeSize={{ x: 300, y: 360 }}
                />
              ) : (
                <p className="text-gray-500 text-center mt-2">No Data.</p>
              )}
            </div>

            {/* Right Button */}
            {currentPage < treeData?.length - 1 && (
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className="pagination-button flex justify-center items-center"
                aria-label="Go to next page"
              >
                <FaChevronRight color="#818589" size={32} />
              </button>
            )}
          </div>
        </div>
      </CustomScrollbar>

      {/* Modal for goal details */}
      {isModalOpen && (
        <ModalContentComponent
          isOpen={isModalOpen}
          onClose={closeModal}
          selectedNode={selectedNode}
        />
      )}
    </div>
  );
};

export default OrganizationHierarchy;
