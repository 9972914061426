import React from "react";

const CustomProgressBar = ({ percentage }) => {
  const outerDivStyle = {
    background: "#E5E7EB",
    border: "2px solid #C0CED3",
    borderRadius: "100px",
    padding: "4px",
    width: `100%`,
    display: "flex",
  };

  const gradientColor = {
    background: "linear-gradient(270deg, #00FF87 1.35%, #0E90FF 124.2%)",
    borderRadius: "100px",
    width: `${percentage}%`,
    transition: "width 5s ease-in-out",
  };

  const CustomTooltip = () => {
    const pointerPosition = percentage === 0 ? `${percentage}%` : `calc(${percentage}% - 20px)`;

    return (
      <div className="custom-tooltip relative">
        <div
          className="tooltip-pointer absolute -bottom-4"
          style={{ left: pointerPosition }} 
        >
          <svg
            width="41"
            height="50"
            viewBox="0 0 41 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.7683 36.9253C37.7655 33.1867 41 27.2211 41 20.5C41 9.17816 31.8218 0 20.5 0C9.17816 0 0 9.17816 0 20.5C0 27.1293 3.14676 33.0237 8.02808 36.771C8.31271 37.2282 8.64954 37.6626 9.03872 38.0657L20.0763 49.4992L31.538 38.4343C32.0173 37.9716 32.4274 37.4643 32.7683 36.9253Z"
                fill="#F27E0D"
              />
              <circle cx="20.5" cy="20.5" r="12" fill="white" />
              <text
                x="20.5"
                y="24"
                textAnchor="middle"
                fontSize="10"
                fill="#000"
              >
                {percentage}%
              </text>
            </g>
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center gap-3 flex-col w-full">
      <CustomTooltip />
      <div style={outerDivStyle}>
        <div style={gradientColor}>
          <p className="opacity-0">H</p>
        </div>
      </div>
    </div>
  );
};

export default CustomProgressBar;
