import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
    backgroundColor: "#F7F8FA",
  },
  contentContainer: {
    flex: 1,
    gap: 30,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: "#111827",
  },
  metricsGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
    justifyContent: "flex-center",
  },
  metricItem: {
    width: "20%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: 12,
    borderRadius: 8,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: "#E5E7EB",
  },
  metricTitle: {
    fontSize: 12,
    color: "#6B7280",
    marginBottom: 4,
  },
  metricValue: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#111827",
  },
  chartRow: {
    flexDirection: "row",
    gap: 20,
    marginBottom: 20,
    alignItems: "center",
  },
  chart: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E5E7EB",
  },
  image: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  fullWidthChart: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: 10,
    borderRadius: 8,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: "#E5E7EB",
  },
});

const ChartsPDF = ({
  pieChartImg,
  scatterChartImg,
  metrics,
  objChartImg,
  gantImage,
  quarterImg,
  contributeImg,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.contentContainer}>
        {/* Metrics Grid */}
        <View style={styles.section}>
          <Text style={styles.header}>Executive Dashboard</Text>
          <View style={styles.metricsGrid}>
            {metrics?.map((metric, index) => (
              <View key={index} style={styles.metricItem}>
                <Text style={styles.metricTitle}>{metric?.label}</Text>
                <Text style={styles.metricValue}>{metric?.value}</Text>
              </View>
            ))}
          </View>
        </View>

        {/* Goals Overview Charts */}
        <View style={styles.section}>
          <Text style={styles.header}>Goals Overview</Text>
          <View style={styles.chartRow}>
            {pieChartImg && (
              <View style={styles.chart}>
                <Image src={pieChartImg} style={styles.image} />
              </View>
            )}
            {scatterChartImg && (
              <View style={styles.chart}>
                <Image src={scatterChartImg} style={styles.image} />
              </View>
            )}
          </View>
        </View>

        {/* Objective Summary */}
        {objChartImg && (
          <View style={styles.section}>
            <Text style={styles.header}>Objective Summary</Text>
            <View style={styles.fullWidthChart}>
              <Image src={objChartImg} style={styles.image} />
            </View>
          </View>
        )}

        {/* Results and Timeline */}
        {(gantImage || quarterImg) && (
          <View style={styles.section}>
            <Text style={styles.header}>Key Result Breakdown</Text>
            {gantImage && (
              <View style={styles.fullWidthChart}>
                <Image src={gantImage} style={styles.image} />
              </View>
            )}
            {quarterImg && (
              <View style={styles.fullWidthChart}>
                <Image src={quarterImg} style={styles.image} />
              </View>
            )}
          </View>
        )}
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      {contributeImg && (
        <View style={styles.section}>
          <Text style={styles.header}>Team Contributions</Text>
          <View style={styles.fullWidthChart}>
            <Image src={contributeImg} style={styles.image} />
          </View>
        </View>
      )}
    </Page>
  </Document>
);

export default ChartsPDF;
