import React, { useEffect, useRef, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import GoalCharts from "./OverallReports/GoalCharts";
import ObjectiveChart from "./OverallReports/ObjectiveChart";
import { api } from "../../components/global/api";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { Button, Modal } from "antd";
import QuarterlyProgressBars from "./OverallReports/QuarterlyProgress";
import KeyGanttChart from "./OverallReports/KeyGantChart";
import ModalContent from "./OverallReports/ModalContent";
import { Spinner } from "@chakra-ui/react";
import { toast } from "react-toastify";

const ExportReports = () => {
  const Sessions = useSelector((state) => state.user?.sessionList);
  const roleAccess = useSelector((state) => state.user?.roleAccess);
  const currentPlan = useSelector((state) => state.user?.currentPlan);
  const pieChartRef = useRef(null);
  const scatterChartRef = useRef(null);
  const objChartRef = useRef(null);
  const chartsWrapperRef = useRef(null);
  const GantRef = useRef(null);
  const quarterlyRef = useRef(null);
  const contributeRef = useRef(null);
  const [objChartImg, setObjChartImg] = useState();
  const [pieChartImg, setPieChartImg] = useState();
  const [scatterChartImg, setScatterChartImg] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({});
  const [gantImage, setGantImage] = useState();
  const [quarterImg, setQuarterImg] = useState();
  const [contributeImg, setContributeImg] = useState();
  const [chartImages, setChartImages] = useState({
    pie: null,
    scatter: null,
    obj: null,
    gant: null,
    quarter: null,
    contribute: null,
  });
  const user = useSelector((state) => state.user);
  const [data, setData] = useState(null);

  const [metrics, setMetrics] = useState([]);
  const currentUser = user?.currentUser;
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const handleChartDownload = async (ref, setImage) => {
    if (ref.current) {
      try {
        const canvas = await html2canvas(ref.current);
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const url = URL.createObjectURL(blob);
        setImage(url);
      } catch (error) {
        console.error("Error capturing chart:", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const company_id =
          currentUser?.employees?.[user?.currentCompany]?.company_id;
        if (!company_id) return;

        const response = await api.get(`${baseURL}/insight/overall-report`, {
          company_id,
        });

        const data = response?.data || response;
        setLoadingData(false);
        if (data) {
          setData(data);
          setMetrics([
            { label: "Goals", value: data?.total_goal || 0 },
            { label: "Objectives", value: data?.total_okr || 0 },
            { label: "Key Results", value: data?.total_key_result || 0 },
            { label: "Teams", value: data?.total_teams || 0 },
          ]);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoadingData(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      handleChartDownload(pieChartRef, setPieChartImg);
      handleChartDownload(scatterChartRef, setScatterChartImg);
      handleChartDownload(objChartRef, setObjChartImg);
      handleChartDownload(GantRef, setGantImage);
      handleChartDownload(quarterlyRef, setQuarterImg);
      handleChartDownload(contributeRef, setContributeImg);
    }
  }, [
    data,
    pieChartRef,
    scatterChartRef,
    objChartRef,
    GantRef,
    quarterlyRef,
    contributeRef,
  ]);

  const processObjectiveData = (data) => {
    // Static bar data for chart legends
    const barData = [
      { dataKey: "completed", fill: "#00C49F", name: "Completed" },
      { dataKey: "inProgress", fill: "#FFBB28", name: "In Progress" },
      { dataKey: "notStarted", fill: "#FF8042", name: "Not Started" },
    ];

    if (!data?.goal_quarter_progress || !data?.okr_quarter_progress) {
      return { barData, stackedBarData: [] };
    }

    // Map over the keys to create stacked bar data
    const stackedBarData = Object.keys(data?.goal_quarter_progress)?.map(
      (quarter) => {
        const okrQuarter = data?.okr_quarter_progress[quarter] || {};
        const matchingQuarter = Sessions?.find((q) => q.value === quarter);
        return {
          name: matchingQuarter?.label || quarter,
          completed: okrQuarter?.completed || 0,
          inProgress: okrQuarter?.in_progress || 0,
          notStarted: okrQuarter?.not_started || 0,
        };
      }
    );

    return { barData, stackedBarData };
  };

  const { barData, stackedBarData } = processObjectiveData(data);

  const COLORS = [
    { start: "#266EEB", end: "#266EEB" },
    { start: "#2CC9A2", end: "#2CC9A2" },
    { start: "#F27E2E", end: "#F27E2E" },
  ];

  const handleButtonClick = async (e) => {
    if (!roleAccess?.Reports?.Download) {
      toast.error("You Dont Have Access To This Feature.");
      return;
    }
    if (currentPlan?.plan_name === "Free" || currentPlan?.free_trial_status) {
      toast.error("Purchase a Plan to Access this Feature.");
      return;
    }
    setLoading(true);

    try {
      await Promise.all([
        new Promise((resolve) =>
          handleChartDownload(pieChartRef, (img) => {
            setChartImages((prev) => ({ ...prev, pie: img }));
            resolve();
          })
        ),
        new Promise((resolve) =>
          handleChartDownload(scatterChartRef, (img) => {
            setChartImages((prev) => ({ ...prev, scatter: img }));
            resolve();
          })
        ),
        new Promise((resolve) =>
          handleChartDownload(objChartRef, (img) => {
            setChartImages((prev) => ({ ...prev, obj: img }));
            resolve();
          })
        ),
        new Promise((resolve) =>
          handleChartDownload(GantRef, (img) => {
            setChartImages((prev) => ({ ...prev, gant: img }));
            resolve();
          })
        ),
        new Promise((resolve) =>
          handleChartDownload(quarterlyRef, (img) => {
            setChartImages((prev) => ({ ...prev, quarter: img }));
            resolve();
          })
        ),
        new Promise((resolve) =>
          handleChartDownload(contributeRef, (img) => {
            setChartImages((prev) => ({ ...prev, contribute: img }));
            resolve();
          })
        ),
      ]);

      // Calculate button position
      const rect = e.target.getBoundingClientRect();
      setButtonPosition({
        top: rect.bottom + window.scrollY + 10,
        right: 12,
      });

      setIsModalOpen(true);
    } catch (error) {
      console.error("Error downloading charts:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!roleAccess?.Reports?.View) {
    <div className="relative h-full">
      <p className="text-gray-500 text-center mt-2">
        You Don't Have Access To This Feature.
      </p>
    </div>;
    return;
  }

  return (
    <div className="relative h-full">
      <CustomScrollbar>
        <div className="flex flex-col w-full h-full mb-12">
          {loadingData ? (
            <div className="flex justify-center items-center h-screen">
              <img src="/assets/images/Group 3.svg" />
            </div>
          ) : !data || data?.length <= 0 ? ( // Ensure data is an array before checking length
            <div className="flex justify-center items-center h-screen">
              <p className="text-gray-500 text-center">No Data Available</p>
            </div>
          ) : (
            <>
              <div className="flex justify-between">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6">
                  Executive Dashboard
                </h2>
                {data && (
                  <button
                    className="px-5 py-1 h-8 flex gap-x-2 items-center rounded-md bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50"
                    onClick={handleButtonClick}
                    disabled={loading}
                  >
                    Export
                    {loading && <Spinner size="sm" />}
                  </button>
                )}
                {isModalOpen && (
                  <Modal
                    visible={isModalOpen}
                    footer={null}
                    onCancel={() => setIsModalOpen(false)}
                    closable={false}
                    width={180}
                    style={{
                      position: "absolute",
                      top: buttonPosition.top,
                      right: buttonPosition.right,
                      padding: 0,
                    }}
                    mask={false}
                  >
                    <ModalContent
                      metrics={metrics}
                      pieChartImg={chartImages.pie}
                      scatterChartImg={chartImages.scatter}
                      objChartImg={chartImages.obj}
                      gantImage={chartImages.gant}
                      quarterImg={chartImages.quarter}
                      contributeImg={chartImages.contribute}
                      handleModalClose={() => setIsModalOpen(false)}
                    />
                  </Modal>
                )}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
                {metrics?.map((metric, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-lg rounded-lg p-6 text-center transition-all duration-300 transform hover:scale-105"
                  >
                    <h3 className="text-lg font-medium text-gray-600 mb-3">
                      <i className="fas fa-chart-pie mr-2"></i>
                      {metric?.label}
                    </h3>
                    <p className="text-3xl font-bold text-gray-800 mt-2">
                      {metric?.value}
                    </p>
                  </div>
                ))}
              </div>
              <GoalCharts
                statsData={data}
                COLORS={COLORS}
                pieChartRef={pieChartRef}
                scatterChartRef={scatterChartRef}
                chartsWrapperRef={chartsWrapperRef}
                setPieChartImg={setPieChartImg}
                setScatterChartImg={setScatterChartImg}
              />
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Overall Objective Summary
              </h2>
              <ObjectiveChart
                barData={barData}
                objChartRef={objChartRef}
                stackedBarData={stackedBarData}
                combinedChartImg={objChartImg}
                setCombinedChartImg={setObjChartImg}
              />
              
              {/* <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Key Result Breakdown
              </h2>
              <KeyGanttChart
                GantRef={GantRef}
                ChartData={data?.key_result_breakdown}
              /> */}
              
              <QuarterlyProgressBars
                data={data}
                quarterlyRef={quarterlyRef}
                contributeRef={contributeRef}
              />
            </>
          )}
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default ExportReports;
