import React, { useState } from "react";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./helper";
import "./styles.css";
import { Avatar, Progress, Tooltip } from "antd";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
} from "../../stores/slices/okrSlice";
import { BiGlobe } from "react-icons/bi";
import { GoGoal } from "react-icons/go";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const containerStyles = {
  width: "100%",
  height: "calc(100vh - 100px)",
  background: "",
  marginTop: "",
};

const getObjectType = (nodeDatum) => {
  let isParent = nodeDatum?.parent ? " / (Parent)" : "";
  if (nodeDatum?.key_id) return "Key Result" + isParent;
  else if (nodeDatum?.okr_id) return "Objective" + isParent;
  else return "Goal" + isParent;
};

export default function App({ data, getOkrData }) {
  const [translate, containerRef] = useCenteredTree();
  const [currentNode, setCurrentNode] = useState(1);
  const user = useSelector((state) => state?.user);
  const nodeSize = { x: 700, y: 300 };
  const separation = { siblings: 1, nonSiblings: 1 };
  const foreignObjectProps = {
    width: nodeSize.x,
    y: -70,
    height: nodeSize.y,
    x: -180,
  };

  const dispatch = useDispatch();
  const handleOpenDrawerWithKey = (node) => {
    if (node?.key_id) {
      if (user?.roleAccess?.["Key-Results"]?.View) {
        dispatch(handleOpenKeyresultDrawer(node?.key_id));
      }
    } else if (node?.okr_id) {
      if (user?.roleAccess?.Objectives?.View) {
        dispatch(handleOpenOkrDrawer(node?.okr_id));
      }
      getOkrData(node.okr_id);
    } else if (node?.goal_id) {
      if (user?.roleAccess?.Goals?.View) {
        dispatch(handleOpenGoalDrawer(node?.goal_id));
      }
    } else {
      console.log("No valid key found in node");
    }
  };

  const handleCustomClick = (nodeData) => {
    setCurrentNode(nodeData?.__rd3t?.depth);
  };

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
  }) => (
    <>
      <g>
        <foreignObject {...foreignObjectProps}>
          <div
            variant="contained"
            onClick={(event) => {
              toggleNode();
              handleCustomClick(nodeDatum);
            }}
            className="rounded p-4 flex flex-col gap-y-2 relative"
            style={{
              backgroundColor: `#fff`,
              borderRadius: "24px",
              border:
                getObjectType(nodeDatum) === "Goal" ||
                getObjectType(nodeDatum) === "Goal / (Parent)"
                  ? "1px solid rgba(185, 28, 28, 0.2)"
                  : getObjectType(nodeDatum) === "Objective" ||
                    getObjectType(nodeDatum) === "Objective / (Parent)"
                  ? "1px solid rgba(34, 76, 194, 0.2)"
                  : "1px solid rgba(5, 94, 38, 0.2)",
              boxShadow:
                getObjectType(nodeDatum) === "Goal" ||
                getObjectType(nodeDatum) === "Goal / (Parent)"
                  ? "0px 4px 11.6px 0px rgba(206, 93, 93, 0.1)"
                  : getObjectType(nodeDatum) === "Objective" ||
                    getObjectType(nodeDatum) === "Objective / (Parent)"
                  ? "0px 4px 11.6px 0px rgba(172, 188, 232, 0.2)"
                  : "0px 4px 11.6px 0px rgba(158, 247, 191, 0.25)",
              cursor: "pointer",
              position: "relative",
              width: "350px",
            }}
          >
            <div className="flex justify-between pb-1">
              <span className="flex w-full">
                <div className="mr-2">
                  {getObjectType(nodeDatum) === "Goal" ||
                  getObjectType(nodeDatum) === "Goal / (Parent)" ? (
                    <button
                      className="rounded p-1"
                      style={{
                        background:
                          "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                      }}
                    >
                      <GoGoal
                        className="text-md"
                        style={{
                          color: "#B91C1C",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </button>
                  ) : getObjectType(nodeDatum) === "Objective" ? (
                    <button>
                      <img
                        src="/assets/images/objective.svg"
                        alt="objective"
                        style={{
                          width: "27px",
                          height: "27px",
                        }}
                      />
                    </button>
                  ) : (
                    <button>
                      <img
                        src="/assets/images/keyresults.svg"
                        alt="objective"
                        style={{
                          width: "27px",
                          height: "27px",
                        }}
                      />
                    </button>
                  )}
                </div>
                <h2 className="text-basic font-bold text-xl">
                  {getObjectType(nodeDatum)}
                </h2>{" "}
              </span>
              <span className=" px-1 flex items-center justify-end">
                <img
                  src={"/assets/images/graphalign.png"}
                  alt="Logo"
                  className="items-center w-5 h-5"
                />
              </span>
            </div>
            <div className="w-full flex items-start flex-col  ">
              <h2
                className="text-basic font-medium text-lg mb-0 "
                onClick={() => handleOpenDrawerWithKey(nodeDatum)}
              >
                {nodeDatum?.okr_title || nodeDatum?.title}
              </h2>
              <div className="flex justify-between w-full">
                <div className="text-sm" style={{ color: "#0C0C0C" }}>
                  {parseInt(nodeDatum?.overall_gain) === 0
                    ? "Pending"
                    : parseInt(nodeDatum?.overall_gain) < 100
                    ? "In Progress"
                    : "Completed"}
                </div>

                <div>
                  <h3
                    className="text-base font-bold"
                    style={{ color: "#063F5D" }}
                  >
                    {parseInt(nodeDatum?.overall_gain)}
                    <span className="font-medium">%</span>
                  </h3>
                </div>
              </div>
              <div className="flex justify-center w-full bg-inherit items-center">
                <Progress
                  percent={parseInt(nodeDatum?.overall_gain)}
                  showInfo={false}
                  strokeColor={
                    parseInt(nodeDatum?.overall_gain) < 100
                      ? "#F77C33"
                      : "#0AA959"
                  }
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex gap-x-2 items-center avatar-group-custom">
                <Avatar.Group
                  maxCount={2}
                  size={"small"}
                  maxPopoverTrigger="click"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    display: "flex",
                    flexDirection: "column !important",
                  }}
                >
                  {nodeDatum?.owners?.map((owner, index) =>
                    nodeDatum.owners.length === 1 ? (
                      <Tooltip
                        key={index}
                        title={
                          owner?.type === "user" ? owner.username : owner.name
                        }
                      >
                        {owner.type === "user" ? (
                          <div
                            key={index}
                            className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                            style={{
                              backgroundColor: owner.profile_image
                                ? "initial"
                                : getAvatarColor(owner.name),
                            }}
                          >
                            {owner.profile_image ? (
                              <img
                                src={owner.profile_image}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                  display: "block",
                                }}
                              />
                            ) : (
                              getInitials(owner.name)
                            )}
                          </div>
                        ) : (
                          <span
                            key={index}
                            className="p-1 bg-gray-600 rounded-full"
                          >
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                        )}
                      </Tooltip>
                    ) : owner.type === "user" ? (
                      <Tooltip key={index} title={owner.name}>
                        <div
                          className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                          style={{
                            backgroundColor: owner.profile_image
                              ? "initial"
                              : getAvatarColor(owner.name),
                          }}
                        >
                          {owner.profile_image ? (
                            <img
                              src={owner.profile_image}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                                display: "block",
                              }}
                            />
                          ) : (
                            getInitials(owner.name)
                          )}
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip key={index} title={owner.name}>
                        <span className="p-1 bg-gray-600 rounded-full">
                          <BiGlobe className="text-gray-300 text-xs" />
                        </span>
                      </Tooltip>
                    )
                  )}
                </Avatar.Group>
              </div>
            </div>
          </div>
        </foreignObject>
      </g>
    </>
  );
  const [currentPage, setCurrentPage] = useState(1);

  const treeIndex = currentPage - 1;
  const isValidIndex = treeIndex >= 0 && treeIndex < data?.length;
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= data.length) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="w-full h-full p-4 font-poppins  ">
      <div className="flex h-full w-full justify-start items-center">
        {currentPage > 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="pagination-button flex justify-center items-center"
          >
            <FaChevronLeft color="#818589" size={32} />
          </button>
        )}
        <div
          className="flex overflow-y-hidden"
          style={containerStyles}
          ref={containerRef}
        >
          {isValidIndex && (
            <Tree
              data={data[treeIndex]}
              translate={translate}
              nodeSize={nodeSize}
              separation={separation}
              transitionDuration="1000"
              pathFunc="step"
              renderCustomNodeElement={(rd3tProps) =>
                renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
              }
              orientation="vertical"
              initialDepth={currentNode}
              styles={{
                links: {
                  stroke: "#bbb",
                  strokeWidth: 2,
                },
              }}
            />
          )}
        </div>
        {currentPage < data?.length && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="pagination-button flex justify-center items-center"
          >
            <FaChevronRight color="#818589" size={32} />
          </button>
        )}
      </div>
    </div>
  );
}
