import { Avatar, Progress, Tooltip } from "antd";
import React from "react";
import { getAvatarColor, getInitials } from "../utils/helpers";
import { MdOutlineFilterList } from "react-icons/md";
import { IoPersonOutline } from "react-icons/io5";
import { GoGoal, GoGraph } from "react-icons/go";
import { BiGlobe } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Box, Text } from "@chakra-ui/react";
import CustomScrollbar from "./global/CustomScrollBar";

function TableRow({ data }) {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const roleAccess = user?.roleAccess;
  const currentEmployee = currentUser?.employees[user?.currentCompany];

  if (!roleAccess?.Goals?.View) {
    return (
      <tr className="mt-2 gap-y-2">
        <td colSpan={3} className="pt-2 text-center text-gray-500">
          You don't have access to view Goals
        </td>
      </tr>
    );
  }

  if (data?.okr_id && !roleAccess?.Objectives?.View) {
    return null;
  }

  return (
    <tr className="mt-2 gap-y-2">
      <td className="pt-2">
        <div className="flex">
          <div className="mr-2 ">
            {!data?.okr_id ? (
              <button
                className="rounded p-1"
                style={{
                  background:
                    "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                }}
              >
                <GoGoal
                  className="text-md"
                  style={{
                    color: "#B91C1C",
                    width: "22px",
                    height: "22px",
                  }}
                />
              </button>
            ) : (
              <button className="flex items-center justify-center space-x-2">
                <div className=" flex items-center h-full">
                  <div className="h-full border-l bg-gray-300"></div>
                  <div className="hidden md:flex items-center justify-end left-0 h-full pl-1">
                    <div className="h-0.5 w-7 bg-gray-300"></div>
                    <div className="h-2 w-2 bg-gray-300 rounded-full ml-1"></div>
                  </div>
                </div>
                <img
                  src="/assets/images/objective.svg"
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                />
              </button>
            )}
          </div>
          <p className="text-sm">{data?.title}</p>
        </div>
      </td>
      <td className="pt-2">
        <div className="flex gap-x-2 justify-center items-center avatar-group-custom">
          <Avatar.Group
            maxCount={2}
            size={"small"}
            maxPopoverTrigger="click"
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              display: "flex",
              flexDirection: "column !important",
            }}
          >
            <Tooltip title={currentUser?.username}>
              <div
                className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                style={{
                  backgroundColor: currentUser?.profile_image
                    ? "initial"
                    : getAvatarColor(currentUser?.username),
                }}
              >
                {currentUser?.profile_image ? (
                  <img
                    src={currentUser?.profile_image}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                      display: "block",
                    }}
                  />
                ) : (
                  getInitials(currentUser?.username)
                )}
              </div>
            </Tooltip>
          </Avatar.Group>
        </div>
      </td>
      <td className="pt-2">
        <div className="flex flex-col w-3/4 items-center justify-center gap-x-2 col-span-1">
          <div className="flex justify-between w-3/4">
            <div className="text-xs" style={{ color: "#0C0C0C" }}>
              {data?.overall_gain === 0
                ? "Pending"
                : data?.overall_gain < 100
                ? "In Progress"
                : "Completed"}
            </div>
            <div>
              <h3 className="text-xs font-bold" style={{ color: "#063F5D" }}>
                {data?.overall_gain}
                <span className="font-medium">%</span>
              </h3>
            </div>
          </div>
          <div className="flex justify-center w-full bg-inherit items-center">
            <Progress
              percent={data?.overall_gain}
              showInfo={false}
              strokeColor={data?.overall_gain < 100 ? "#F77C33" : "#0AA959"}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}

function TableData({ data }) {
  const user = useSelector((state) => state?.user);
  const roleAccess = user?.roleAccess;

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white rounded-lg shadow-sm gap-y-5">
        <thead className="mb-4">
          <tr className="font-normal border h-12 rounded-lg">
            <th className="px-2 py-2 text-left border-r">
              <div className="flex items-center gap-x-2 text-[#626573]">
                <MdOutlineFilterList className="text-[#626573] text-lg" />
                <span>Name</span>
              </div>
            </th>
            <th className="px-2 py-2 text-center border-r">
              <div className="flex items-center justify-center gap-x-2 text-[#626573]">
                <IoPersonOutline className="text-[#626573] text-lg" />
                <span>Owners</span>
              </div>
            </th>
            <th className="px-2 py-2 text-center border-r">
              <div className="flex items-center justify-center gap-x-2 text-[#626573]">
                <GoGraph className="text-[#626573] text-lg" />
                <span>Progress</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="mt-4">
          {data?.length === 0 ? (
            <tr>
              <td colSpan="5">
                <Box py={4} textAlign="center">
                  <Text className="text-gray-500 text-center mt-2">
                    No Data Found
                  </Text>
                </Box>
              </td>
            </tr>
          ) : !roleAccess?.Goals?.View ? (
            <tr className="mt-2 gap-y-2">
              <td colSpan={3} className="pt-2 text-center text-gray-500">
                You don't have access to view Goals
              </td>
            </tr>
          ) : data?.length >= 3 ? (
            data
              ?.slice(0, 3)
              ?.map((item, index) => <TableRow key={index} data={item} />)
          ) : (
            data?.length > 0 && (
              <>
                <TableRow data={data[0]} />
                {data[0]?.children?.slice(0, 2)?.map((child, childIndex) => (
                  <TableRow key={childIndex} data={child} />
                ))}
              </>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TableData;
